import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

class UsersIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clientUUID:'-ohlnfwebk',
            orders:[],
            boards:[]
            
        };
    }

    getOrders(){


    }


    resetUserPassword(mail){
        console.log('herro')
        toast.success('Wachtwoord reset is verstuurd');
        Firebase.auth().sendPasswordResetEmail(mail).then(()=> console.log('reset mail send')).catch((e)=>console.log(e))
      }
    componentDidMount() {
     
        let items = Firebase.database().ref('/clients/-beifkwefiwebfnw/users');
        items.on('value' , snapshot => {
            this.setState({
                boards:[]
            })
    
            snapshot.forEach((childSnap) => {
                let newBoard = childSnap.val();  
            
                this.setState(prevState =>({
                    boards: [...prevState.boards, newBoard]
                }))
            })
        })

    }

    changeRole(role,uuid){
        Firebase.database().ref('/clients/-beifkwefiwebfnw/users/'+uuid).update({
            role: role,
           
             
           }).then((data)=>{
            toast.success('Rol is gewijzigd');
           }).catch((error)=>{
               //error callback
               console.log('error ' , error)
           })
    }

    

    render() {
        return (
            <div>
                         <Toaster />
            <Row className="col-sm-12">
                {/*<Col sm={12} className="products-actions">
                    <Link>Product bewerken</Link>
                    <Link>Meer acties</Link>
                    <Link className="align-button-right" to={{pathname: `/producten/toevoegen`}} >Product toevoegen</Link>
        </Col>*/}
                <Col sm={12} className="products-table">
                
            <table>
                <tr>
                    <th>Naam</th>
                    <th>Gebruikersnaam</th>
                    <th>Rol</th>
                    <th>Actie</th>
                </tr>
                {this.state.boards.map((item)=>(
                    <tr >
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td><select value={item.role} onChange={(event)=> this.changeRole(event.target.value,item.uuid)}><option value="user">Gebruiker</option><option value="admin">Administrator</option></select></td>
                        <td><Button onClick={()=>this.resetUserPassword(item.email)}>Reset wachtwoord</Button>{/*} <Button>Verwijder</Button>*/} </td>
                        
                    </tr>
                ))}


                
                
            </table>
</Col>
   
            </Row>
            </div>



        );
    }
};

export default UsersIndex;