import React from "react";
import { Redirect } from "react-router-dom";
import AddBoardAPM from "./components/boards/AddBoard";
import AddUserAPM from "./components/boards/AddUserApm";

// Layout Types
import { DefaultLayout, ModalLayout } from "./layouts";
import AdminPanel from "./views/Admin/AdminPanelView";
import AddToMyDesk from "./views/AppFrontend/AddToMyDesk";
import APMBoarView from "./views/AppFrontend/ApmBoardView";
import EditPostViewSecond from "./views/AppFrontend/EditPostView";
import EditPostView from "./views/AppFrontend/EditPostView";
import LoginSelectionScreen from "./views/AppFrontend/LoginSelectionView";
import myDeskView from "./views/AppFrontend/MyDesk";
import PoEditScreen from "./views/AppFrontend/PoEditScreen";
import PostItView from "./views/AppFrontend/PostItView";
import SignOnCompleteView from "./views/Authentication/SignOnCompleteView";
import SignOnView from "./views/Authentication/SignOnView";
import AddAPMBoardView from "./views/Boards/AddBoardView";
import AddAPMBoard from "./views/Boards/AddBoardView";
import AddBoardScreenApm from "./views/Boards/AddBoardView";
import AddUserScreenApm from "./views/Boards/AddUserView";
import BoardsView from "./views/Boards/BoardView";
import CreateCatView from "./views/Boards/CreateCatView";
import DetailApmView from "./views/Boards/DetailsApmView";
import Changes from "./views/Changes/ChangesView";
import ChangesReview from "./views/Changes/ReviewChanges";
import Downloads from "./views/Downloads/IndexView";
import LoginScreen from "./views/LoginScreen";
import Message from "./views/Messages/MessageView";
import Orders from "./views/Orders/OrdersView";
import AddProductsView from "./views/Products/AddProductsView";
import ChangeProductsView from "./views/Products/ChangeProductsView";
import Products from "./views/Products/ProductsView";
import Profile from "./views/Profile/ProfileView";
import QArchiveChangeView from "./views/qsort/QArchiveChangeView";
import QArchiveSeenView from "./views/qsort/QArchiveSeenView";
import QArchiveView from "./views/qsort/QArchiveView";
import SettingsQsortView from "./views/qsort/SettingsView";


// Route Views

import SignInScreen from "./views/signInView";
import UsersView from "./views/Users/UserView";



export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/kies-bord" />
  },
  {
    path: "/aanmeldingen",
    exact: true,
    layout: DefaultLayout,
    component: Changes
  },
  {
    path: "/mijn-borden",
    exact: true,
    layout: DefaultLayout,
    component: BoardsView
  },
  {
    path: "/apm-bord",
    exact: true,
    layout: ModalLayout,
    component: APMBoarView
  },  {
    path: "/apm-bord/kaart-aanpassen-po",
    exact: true,
    layout: ModalLayout,
    component: PoEditScreen
  },
  {
    path: "/apm-bord/kaart-aanpassen",
    exact: true,
    layout: ModalLayout,
    component: EditPostViewSecond
  },

  {
    
    path: "/mijn-borden/aanmaken",
    exact: true,
    layout: DefaultLayout,
    component: AddAPMBoardView
  },
  {
    
    path: "/kies-bord",
    exact: true,
    layout: ModalLayout,
    component: LoginSelectionScreen
  },
  {
    
    path: "/mijn-bureau",
    exact: true,
    layout: DefaultLayout,
    component: myDeskView
  },  {
    
    path: "/mijn-bureau/aanmaken",
    exact: true,
    layout: DefaultLayout,
    component: AddToMyDesk
  },
  {
    
    path: "/mijn-borden/aanmaken-categorie",
    exact: true,
    layout: DefaultLayout,
    component: CreateCatView
  },
  {
    
    path: "/mijn-borden/details",
    exact: true,
    layout: DefaultLayout,
    component: DetailApmView
  },
  {
    
    path: "/mijn-borden/gebruiker-toevoegen",
    exact: true,
    layout: DefaultLayout,
    component: AddUserScreenApm
  },
  {
    path: "/gebruikers",
    exact: true,
    layout: DefaultLayout,
    component: UsersView
  },
  {
    path: "/aanmeldingen/beoordelen",
    exact: true,
    layout: DefaultLayout,
    component: ChangesReview
  },
  {
    path: "/producten",
    exact: true,
    layout: DefaultLayout,
    component: Products
  },
  {
    path: "/producten/toevoegen",
    exact: true,
    layout: DefaultLayout,
    component: AddProductsView
  },
  {
    path: "/producten/aanpassen",
    exact: true,
    layout: DefaultLayout,
    component: ChangeProductsView
  },
  {
    path: "/bestellingen",
    exact: true,
    layout: DefaultLayout,
    component: Orders
  },
  {
    path: "/instellingen",
    exact: true,
    layout: DefaultLayout,
    component: Profile
  },
  {
    path: "/berichten",
    exact: true,
    layout: DefaultLayout,
    component: Message
  },
  {
    path: "/downloads",
    exact: true,
    layout: DefaultLayout,
    component: Downloads
  },
  {
    path: "/instellingen-q-sort",
    exact: true,
    layout: DefaultLayout,
    component: SettingsQsortView
  },
  {
    path: "/archief-q-sort",
    exact: true,
    layout: DefaultLayout,
    component: QArchiveView
  },
  {
    path:'/archief-q-sort-details',
    exact: true,
    layout: DefaultLayout,
    component: QArchiveSeenView
  },
  {
    path:'/archief-q-sort-aanpassen',
    exact: true,
    layout: DefaultLayout,
    component: QArchiveChangeView
  },
  {
    
    path: "/sign-in",
    layout: ModalLayout,
    exact: true,
    component: SignInScreen
  },
  {
    
    path: "/admin",
    layout: DefaultLayout,
    exact: true,
    component: AdminPanel
  },



];
