import React from "react";
import { Container, Row, Col } from "shards-react";
import MyDesk from "../../components/appFrontend/MyDesk";


import PageTitle from "../../components/common/PageTitle";



const myDeskView = (props) => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Mijn bureau" subtitle="F-Fort" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <MyDesk data={props.location.state}/>
    
  </Container>
);

export default myDeskView;
