import React from "react";
import { Container, Row, Col } from "shards-react";



import StartQsortScreen from "../../components/qsort/StartScreen";

import desktopImage from '../../assets/images/background-blobs.png';
import mobileImage from '../../assets/images/background-blobs.png';



const imageUrl = window.innerWidth >= 650 ? desktopImage : mobileImage;

const StartQsortView = ({match, location}) => (
  <Container fluid className="main-content-container px-4 modal-background" style={{backgroundImage: `url(${imageUrl})` }}>





    <StartQsortScreen id={location.search}/>
    
  </Container>
);

export default StartQsortView;
