import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import ProductsIndex from "../../components/products";
import QArchiveScreen from "../../components/qsort/QArchiveScreen";
import QArchiveSeenScreen from "../../components/qsort/QArchiveSeenScreen";



const QArchiveSeenView = (props) => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Q-sort Archief - details" subtitle="F-Fort" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <QArchiveSeenScreen data={props.location.state}/>
    
  </Container>
);

export default QArchiveSeenView;
