import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import "../src/assets/chashierr.css";
import "../src/assets/desktop-css.css";
import "../src/assets/notebook-css.css";
import "../src/assets/tablet-css.css";
import "../src/assets/mobile-css.css";
import LoginScreen from "./views/LoginScreen";
import PrivateRoute from "./privateRoute";
import SignOnView from "./views/Authentication/SignOnView";
import SignOnCompleteView from "./views/Authentication/SignOnCompleteView";
import AdminSignUp from "./views/Admin/AdminSignUpView";
import FrontendHome from "./views/Frontend/FrontendHome";
import { Link } from "react-router-dom";
import StartQsortView from "./views/qsort/StartView";
import SortingQsortView from "./views/qsort/SortingView";
import QsortingQsortView from "./views/qsort/QsortingView";
import EndQsortView from "./views/qsort/EndView";
import QsortingCheckView from "./views/qsort/QsortingCheckView";


export default () => (
  <Router basename={process.env.REACT_APP_BASENAME || ""}>
    <Switch>
    
    <div>
   <Route  path="/inloggen" component={LoginScreen}/>
   <Route exact path="/aanmelden" component={SignOnView}/>
 
   <Route exact path="/home" component={FrontendHome}/>
   <Link to="/q-sort?company=:company?id=:id"/>
    <Route path="/q-sort" component={StartQsortView}/>
    <Route path="/q-sort-sorting" component={SortingQsortView}/>
    <Route path="/q-sorting" component={QsortingQsortView}/>
    <Route path="/q-sort-check" component={QsortingCheckView}/>
    <Route path="/q-sort-done" component={EndQsortView}/>

  
   <Route exact path="/aanmelden-compleet" component={SignOnCompleteView}/>
      {routes.map((route, index) => {
        return (
          <PrivateRoute
            key={index}
            path={route.path}
            exact={route.exact}
            component={withTracker(props => {
              return (
                <route.layout {...props}>
                  <route.component {...props} />
                </route.layout>
              );
            })}
          />
        );
      })}
    </div>
    </Switch>
  </Router>
);
