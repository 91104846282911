import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link, Redirect } from 'react-router-dom';
import Firebase from 'firebase';



class AddBoardAPM extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            employee:'',
            from:'',
            till:'',
            target:'',
            targetDescription:'',
            uploaded:false,
            categories:[],
            redirect:false
        }

      }


      componentDidMount(){
     
      }

      addBoard(){
        var newPostKey = Firebase.database().ref().child('/clients/-beifkwefiwebfnw/boards/').push().key;
        localStorage.setItem('id',newPostKey)
       
        Firebase.database().ref('/clients/-beifkwefiwebfnw/boards/'+newPostKey).set({
          employee: this.state.employee,
          key: newPostKey,
          name: this.state.name,
          nextSession: this.state.till,
          
          settings:{
              live: false,
              pieces: 0,
              targetSettings:{
                  targetTitle: this.state.target,
                  targetDescription: this.state.targetDescription
              },
              till: this.state.till,
              from: this.state.from,
          },
          status: 'In progress',
      }).then((data)=>{
            this.setState({
                uploaded: true,
                redirect:true
            })
      }).catch((error)=>{
          //error callback
          console.log('error ' , error)
      })
      }
          
    render() {
        if (this.state.redirect) {
            return <Redirect to={'/mijn-borden/aanmaken-categorie'} />
          }
        return (
            <Row >
                <Col lg="2"></Col>
                <Col lg="8" className="col-height">
                    <div className="edit-screen-detail">
                        
                        <h4>Bord aanmaken</h4>
                        <br/>
                        <li><span className="align-left">Naam</span> <span className="align-right board-setup"><input type="text" value={this.state.name} onChange={(event)=> this.setState({name: event.target.value, uploaded: false})} /></span></li>
                        <li><span className="align-left">Verantwoordelijke</span> <span className="align-right board-setup"><input type="text" value={this.state.employee} onChange={(event)=> this.setState({employee: event.target.value, uploaded: false})} /></span></li>
                        <li><span className="align-left">Bord van</span> <span className="align-right board-setup"><input type="date" value={this.state.from} onChange={(event)=> this.setState({from: event.target.value})} /></span></li>
                        <li><span className="align-left">Bord tot</span> <span className="align-right board-setup"><input type="date" value={this.state.till} onChange={(event)=> this.setState({till: event.target.value})} /></span></li>
                        <li><span className="align-left">Doel</span> <span className="align-right board-setup"><input type="text" value={this.state.target} onChange={(event)=> this.setState({target: event.target.value, uploaded: false})} /></span></li>
                        <li><span className="align-left">Doel omschrijving</span> <span className="textarea"><textarea  value={this.state.targetDescription} onChange={(event)=> this.setState({targetDescription: event.target.value})} /></span></li>           
                        <span className="marginTop">
                        <Button className="save-button" variant="primary" onClick={()=> this.addBoard()}>Aanmaken</Button> {this.state.uploaded ?<span className="post-it-update"> <i class="fas fa-check"></i>  Bord is aangemaakt.</span> :null}<br />
                 
                        </span>

                       
                       
                    </div>
                </Col>
                <Col lg="2"></Col>
            </Row>         
        );
    }
};

export default AddBoardAPM;
