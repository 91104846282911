

export default function() {
  return [
    /*  {
      title: "Bestellingen",
      to: "/bestellingen",
      htmlBefore: 'shopping-cart',
      htmlAfter: ""
    }, 
    {
      title: "Producten",
      to: "/producten",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/systeem-foto%2Flabel.svg?alt=media&token=94da3c67-cc4f-48bc-8e83-181c2d1369f2',
      htmlAfter: ""
    }, 
    {
      title: "Mijn profiel",
      to: "/profiel",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/systeem-foto%2Fuser.svg?alt=media&token=392e2cd0-7a58-4272-8390-2ab56a59fd15',
      htmlAfter: ""
    },*/
    {
      title: "Mijn Bureau",
      to: "/mijn-bureau",
      htmlBefore:  'https://firebasestorage.googleapis.com/v0/b/f-fort-tool-2.appspot.com/o/desk.svg?alt=media&token=a7fea8e3-6456-4657-a8e8-8e0dac69fe9d',
      htmlAfter: "",
      admin: false
    }, 
    {
      title: "Wissel van bord",
      to: "/kies-bord",
      htmlBefore:  'https://firebasestorage.googleapis.com/v0/b/f-fort-tool-2.appspot.com/o/swap.svg?alt=media&token=c507abb0-1958-419e-bdb7-35b185c62e0f',
      htmlAfter: "",
      admin: false
    }, 
 
    {
      title: "Borden",
      to: "/mijn-borden",
      htmlBefore:  'https://firebasestorage.googleapis.com/v0/b/f-fort-tool-2.appspot.com/o/dashboard.svg?alt=media&token=8aa4f0f2-1ff1-44aa-b8b4-f6c55dd6455b',
      htmlAfter: "",
      admin: true
    }, 
    {
      title: "Gebruikers",
      to: "/gebruikers",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/f-fort-tool-2.appspot.com/o/user%20(1).svg?alt=media&token=91cc14eb-6775-4c61-b746-98a3bee3b07a',
      htmlAfter: "",
      admin: true
    }, 
    {
      title: "Instellingen (Q)",
      to: "/instellingen-q-sort",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/f-fort-tool-2.appspot.com/o/user%20(1).svg?alt=media&token=91cc14eb-6775-4c61-b746-98a3bee3b07a',
      htmlAfter: "",
      admin: true
    },{
      title: "Archief (Q)",
      to: "/archief-q-sort",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/f-fort-tool-2.appspot.com/o/user%20(1).svg?alt=media&token=91cc14eb-6775-4c61-b746-98a3bee3b07a',
      htmlAfter: "",
      admin: true
    },  /*{
      title: "Instellingen",
      to: "/instellingen",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/systeem-foto%2Fdownload-solid.svg?alt=media&token=a723caa9-e04c-4f0b-aedf-c56b3a8a880a',
      htmlAfter: "",
      admin: true
    },    */
  ];
}
