import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";

import { Link } from "react-router-dom";
import AddBoardAPM from "../../components/boards/AddBoard";
import AppDetailOverviewApm from "../../components/boards/DetailsApmComponent";



const DetailApmView = (props) => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title={"Bord overzicht"} subtitle="F-Fort" md="12" className="ml-sm-auto mr-sm-auto" />
      <Link to={{ pathname:'/mijn-borden/' ,state: {}}}>Terug</Link>
    </Row>
    <AppDetailOverviewApm data={props.location.state}/>
    
  </Container>
);

export default DetailApmView;
