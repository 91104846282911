import React from "react";
import { Container, Row, Col } from "shards-react";


import desktopImage from '../../assets/images/background-blobs.png';
import mobileImage from '../../assets/images/background-blobs.png';
import EndQsortScreen from "../../components/qsort/EndScreen";


const imageUrl = window.innerWidth >= 650 ? desktopImage : mobileImage;
const EndQsortView = (props) => (
  <Container fluid className="main-content-container px-4 modal-background"  style={{backgroundImage: `url(${imageUrl})` }}>


    <EndQsortScreen qsortdata={props.location.state}/>
    
  </Container>
);

export default EndQsortView;
