import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Row, Col,Button} from "shards-react";
import Firebase from 'firebase';
import ArrowDownIcon from '../../assets/images/svg/chevron-down-solid.svg'
import EditIcon from "../../assets/images/svg/pen-solid.svg"
import DeleteIcon from "../../assets/images/svg/times-solid.svg"
import { Title } from '@material-ui/icons';
import { Link } from 'react-router-dom';

class AppDetailOverviewApm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employee: '',
            key: '',
            name: '',
            nextSession: '',
            status: '',
            settings:[],
            target:'',
            pieces: 0,
            description: '',
            till:'',
            users:[],
            categories:[],
            live: false,
            projectKey:'',
            clientKey:'',
            id:''
        }
    }



  


    componentWillMount() {
        console.log(this.props.data.item.key);
    
      this.setState({
        categories:[],
        id: this.props.data.item.key,
        clientName:  this.props.data.item.name,
      })
      let apps = Firebase.database().ref('/clients/-beifkwefiwebfnw/boards/'+this.props.data.item.key);
      apps.on('value' , snapshot => {
        let state = snapshot.val();

        this.setState({
            employee: state.employee,
            key: state.key,
            name: state.name,
            nextSession: state.nextSession,
            status: state.status,
            settings: state.settings,
           

        })
      })

      let users = Firebase.database().ref('/clients/-beifkwefiwebfnw/boards/'+this.props.data.item.key+'/participants/');
      users.on('value' , snapshot => {
          snapshot.forEach((childSnap) => {

          let state = childSnap.val();
          var newelement = {userUid: state.uuid, author: state.name, email:state.email};
          this.setState(prevState => ({
            
            users: [...prevState.users, newelement],
          }));
        
          });
      })

      let messagesRef = Firebase.database().ref('/clients/-beifkwefiwebfnw/boards/'+this.props.data.item.key+'/settings/targetSettings');
        messagesRef.on('value', snapshot => {

          let targets = snapshot.val();

          this.setState({
            target:targets.targetTitle,
            description: targets.targetDescription

          })

          console.log(targets)

        })



        let categoriesRef = Firebase.database().ref('/clients/-beifkwefiwebfnw/boards/'+this.props.data.item.key+'/settings/categories');
        categoriesRef.on('value' , snapshot => {
          this.setState({categories:[]})
            snapshot.forEach((childSnap) => {
  
            let state = childSnap.val();
            
            this.setState(prevState => ({
              
              categories: [...prevState.categories, state],
            }));
          
            });
        })
        
        let settingsRef = Firebase.database().ref('/clients/-beifkwefiwebfnw/boards/'+this.props.data.item.key+'/settings/');
        settingsRef.on('value', snapshot => {

          let settings = snapshot.val();

          this.setState({
            till:settings.till,
            live: settings.live

          })


        })
          
    }

    updateTarget(){
      Firebase.database().ref('/clients/-beifkwefiwebfnw/boards/'+this.props.data.item.key+'/settings/targetSettings').update({
       targetTitle: this.state.target,
       targetDescription:  this.state.description,
        
      }).then((data)=>{
      }).catch((error)=>{
          //error callback
          console.log('error ' , error)
      })

      Firebase.database().ref('/clients/-beifkwefiwebfnw/boards/'+this.props.data.item.key+'/settings/').update({
          till: this.state.till,
          live: this.state.live
         
       }).then((data)=>{
       }).catch((error)=>{
           //error callback
           console.log('error ' , error)
       })
    }

    getCategories(){
      console.log('get here');

      let categoriesBackup = [...this.state.categories];
      this.setState({
        categories:[]
      })

      categoriesBackup.forEach((category, i) =>{

        categoriesBackup[i].key = i;
        categoriesBackup[i].position = i;
      
      console.log(i)
      })


      this.setState({
        categories: categoriesBackup
      })

      Firebase.database().ref('/clients/-beifkwefiwebfnw/boards/'+this.props.data.item.key+'/settings/').update({
        categories: categoriesBackup,

       
     }).then((data)=>{
     }).catch((error)=>{
         //error callback
         console.log('error ' , error)
     })

    }

    updateCategory(){
      Firebase.database().ref('/clients/-beifkwefiwebfnw/boards/'+this.props.data.item.key+'/settings/').update({
        categories: this.state.categories,
       
         
       }).then((data)=>{
       }).catch((error)=>{
           //error callback
           console.log('error ' , error)
       })
    }
    positionPlus(key,position){

      const index = this.state.categories.findIndex(category => category.key == key );
      const indexMin = this.state.categories.findIndex(category => category.position == (position -1) && category.key != key);

      let newArray = [...this.state.categories];

      newArray[index] = {...newArray[index], position: newArray[index].position - 1 };

      newArray[indexMin] = {...newArray[indexMin], position: newArray[indexMin].position + 1 };


      this.setState({
        categories: newArray,
      });
    }

    positionMin(key,position){
      const index = this.state.categories.findIndex(category => category.key == key );
      const indexMin = this.state.categories.findIndex(category => category.position == (position +1) && category.key != key);

      let newArray = [...this.state.categories];

      newArray[index] = {...newArray[index], position: newArray[index].position + 1 };

      newArray[indexMin] = {...newArray[indexMin], position: newArray[indexMin].position - 1 };


      this.setState({
        categories: newArray,
      });
    }

    changeStatus(){
      console.log('clicked')
      if(this.state.live == true){
        this.setState({
          live:false
        })
      }else if(this.state.live == false){
        this.setState({
          live:true
        })
      }
    }

    changeCategoryProperty(key,position){
      const index = this.state.categories.findIndex(category => category.poistion == position );
      let newArray = [...this.state.categories];
      newArray[index] = {...newArray[index], bgcolor: newArray[index].position + 1 };


    }

    reorder(){
      let categoriesOld = [...this.state.categories];

      for(let i = 1; i < this.state.categories.length; i++){
          let newElement = {bgcolor: categoriesOld[i].bgcolor, key: i,name:categoriesOld[i].name,po:categoriesOld[i].po, position:i};
          this.setState(prevState => ({
              
            categories: [...prevState.categories, newElement],
          }));

      }

      this.setState({
        categories: this.state.categories
      })
    }

    deleteUserFromBoard(uid){
        Firebase.database().ref('/admin/apps/'+this.props.data.projectKey+'/clients/'+this.props.data.clientKey+'/participants/'+uid).remove();
        this.setState({users:[]})
        let users = Firebase.database().ref('/admin/apps/'+this.props.data.projectKey+ '/clients/'+this.props.data.clientKey+'/participants/');
        users.on('value' , snapshot => {
            snapshot.forEach((childSnap) => {
  
            let state = childSnap.val();
            var newelement = {userUid: state.userUid, name: state.name, email: state.email};
            this.setState(prevState => ({
              
              users: [...prevState.users, newelement],
            }));
          
            });
        })
      }


    deleteCategory(key, position){
      Firebase.database().ref('/clients/-beifkwefiwebfnw/boards/'+this.props.data.id+'/settings/categories/'+key).remove();

     // this.reorder();
      this.getCategories();
    }
    resetUserPassword(mail){
        Firebase.auth().sendPasswordResetEmail(mail).then(()=> console.log('reset mail send')).catch((e)=>console.log(e))
      }

    render() {

        return (
            
            <Row>
            <Row className="head-block">
                <Col sm={8}>

                </Col>
                <Col sm={2}> </Col>
                <Col sm={2}>
               
                </Col>
                
                </Row>
                
                   <Col lg={12}>
                   <table className="app-table" >
                      <tr>
                        <th>Klant</th>
                        <th>Verantwoordelijke</th>
                        <th>Status</th>
                        <th>Volgende sessie</th>
                        <th></th>
                      </tr>

                          <tr className="app-overview-table-row">
                            <td>{this.state.name}</td>
                            <td>{this.state.employee}</td>
                            <td>{this.state.status}</td>
                            <td>{this.state.nextSession}</td>
                            <td><img src={ArrowDownIcon} className="svg-icon right-svg-icon" /></td>
                          </tr>
                  
               
                    </table>

                   </Col>

                   <Col sm={6} className="margin-top">
                   <h4>Bord informatie</h4>
                      <div className="small-settings-section">
                        <li><span className="align-left">Volgende sessie</span> <span className="align-right board-setup"><input type="date" value={this.state.till} onChange={(event)=> this.setState({till: event.target.value})} /></span></li>
                       
                        <li><span className="align-left">Doel</span> <span className="align-right board-setup"><input type="text" value={this.state.target} onChange={(event)=> this.setState({target: event.target.value})} /></span></li>
                        <li><span className="align-left">Doel omschrijving</span> <span className="textarea"><textarea  value={this.state.description} onChange={(event)=> this.setState({description: event.target.value})} /></span></li>
                        
                        <div className="settings-div">
                          {/*<Button onClick={()=> console.log('clicked')} className="recover-button" variant="primary" onClick={()=> console.log('reset values')}>Herstellen</Button>*/}
                          <Button className="save-button" variant="primary" onClick={()=> this.updateTarget()}>Opslaan</Button>

                        </div>
                      </div>

                   </Col>
                   <Col sm={6} className="margin-top">
                   <h4>Categorieën</h4>
                      <div className="small-settings-section">
                      { this.state.categories.sort((a, b) => a.position > b.position ? 1 : -1).map((category, i) => ( 
                        <li className="category-list-item"><span className="category-item">{category.name}</span> {/*<span className="align-right-actions"><img src={DeleteIcon} className="svg-icon right-svg-icon arrow-up" onClick={()=> this.deleteCategory(category.key, category.position)} /><Link to={{pathname:'/dashboard/app-overview/app-details-apm/change-category', state:{ category: category, projectKey: this.state.projectKey, clientKey: this.state.clientKey, toolName: this.state.toolName, clientName: this.state.clientName}}}><img src={EditIcon} className="svg-icon right-svg-icon"  /></Link><img src={ArrowDownIcon} className="svg-icon right-svg-icon arrow-up" onClick={()=> this.positionPlus(category.key, category.position)} /><img src={ArrowDownIcon} className="svg-icon right-svg-icon"  onClick={()=> this.positionMin(category.key, category.position)}/> </span>*/}</li>
                      ))}
                        
                        
                        <div className="settings-div">
                         {/* <Link to={{pathname: '/dashboard/app-overview/app-details-apm/add-category', state:{  projectKey: this.state.projectKey, clientKey: this.state.clientKey, toolName: this.state.toolName, clientName: this.state.clientName}}}><Button onClick={()=> console.log('clicked')} className="recover-button" variant="primary">Toevoegen</Button></Link>
  
                          <Button className="save-button" variant="primary" onClick={()=> this.updateCategory()}>Opslaan</Button>*/}

                        </div>
                      </div>

                   </Col>
                   <Col sm={6} className="margin-top">
                   <h4>Gebruikers</h4>
                      <div className="small-settings-section">
                                {this.state.users.map((user,i) =>(
                                     <li><span className="align-left">{user.author}</span> <span className="align-right board-setup-users"><Button  className="delete-button" onClick={()=> this.deleteUserFromBoard(user.userUid)}>Verwijder gebruiker</Button><Button className="reset-button" onClick={()=> this.resetUserPassword(user.email)}>Reset wachtwoord</Button></span></li>
                                ))}
                        <div className="settings-div">
                          <Link to={{pathname: '/mijn-borden/gebruiker-toevoegen', state:{  id: this.state.id,name:this.state.name}}}><Button onClick={()=> console.log('clicked')} className="recover-button" variant="primary">Toevoegen</Button></Link>
                        </div>
                      </div>
                   </Col>
                   
            </Row>



        );
    }
};

export default AppDetailOverviewApm;