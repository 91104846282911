import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toast, { Toaster } from 'react-hot-toast';

const notify = () => toast('Je wijzigingen zijn opgeslagen!');

class QArchiveChangeScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questions:[],
            stelling:'',
            companyText:''
        };
    }

   

    changeProduct(){

      


        Firebase.database().ref('/clients/'+this.props.data.id+'/q-sort/sessions/'+this.props.data.key).update({
            questions:this.state.questions,
            stelling: this.state.stelling,
            companyText:this.state.companyText
            
    
        }).then((data)=>{

            toast.success('Wijzigingen opgeslagen')
           

        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        })

  


    }

    


    componentWillMount() {
        this.setState({
            clientUUID: Firebase.auth().currentUser.uid
        })

        let ref = Firebase.database().ref('/clients/'+this.props.data.id+'/q-sort/sessions/'+this.props.data.key+'/questions/');
        ref.on('value' , snapshot => {

            let state = snapshot.val()
            console.log(state)
            this.setState({
                questions: state
            })
           
   
         


            
        });

        let stelling = Firebase.database().ref('/clients/'+this.props.data.id+'/q-sort/sessions/'+this.props.data.key);
        stelling.on('value' , snapshot => {

            let state = snapshot.val()
            console.log(state)
            this.setState({
                stelling: state.stelling,
                companyText:state.companyText
            })
           
   
         


            
        });
      

        this.setState({
   
            id:this.props.data.id,
            key:this.props.data.key,
            
        })

       

   
       
    }


    changeQuestion(value, question){
        console.log(value);
        console.log(question);
     
        this.state.questions.map((questionmap)=> questionmap.id == question.id ? question.vraag = value :null);
        this.setState({
            questions: this.state.questions
        })
    }
    
    

    render() {
        return (
            <div>
                <Toaster />
            <Row className="col-sm-12">
                <Col sm={12} className="products-actions">
                    <Link to={{pathname: `/archief-q-sort`}}>Terug</Link>
                    <Button className="main-save-button" onClick={()=>this.changeProduct()}>Opslaan</Button>
                    {/*<Link className="align-button-right">Product toevoegen</Link>*/}
                </Col>
                <Col sm={8} className="colum-left">
                    <Row>
                        <Col sm={12} className="add-product-colum">
                            <label>Stelling</label><br/>
                            <input type="text" value={this.state.stelling} onChange={(event)=>this.setState({stelling: event.target.value,changes:true})}/><br/><br/>
                            <label>Welkomsttekst</label><br/>
                            <textarea  value={this.state.companyText} onChange={(event)=>this.setState({companyText: event.target.value,changes:true})}/><br/><br/>
                            <label>Vragen</label><br/>
                            {this.state.questions.map((question)=>(
                                <input type="text" className="q-sort-change-question" value={question.vraag} onChange={(event)=> this.changeQuestion(event.target.value, question)}/>
                            ))}
                            
                        </Col>
                        </Row>
                </Col>
   
            </Row>
   
            </div>



        );
    }
};

export default QArchiveChangeScreen;