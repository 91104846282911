import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';



// fake data generator
const getItems = (count, offset = 0) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k + offset}`,
        content: `item ${k + offset}`
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid,
    width: 250
});

class QSortingQsortCheckScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

           logo:'',
 
            post01: [],
            post02:  [],
            post00:  [],
            post11: [],
            post12: [],
            user:   localStorage.getItem('user-key'),
            saveKey:  localStorage.getItem('save-key'),
            stelling:'',
            isUnlocked: false,
            donesorting: false,
            errorMessage:'',
            afronden: false,
            loading: false,
            post01Open:false,
            post02Open:false,
            post00Open:false,
            post11Open:false,
            post12Open:false,
            errorMessageActive:false,
            negative:'',
            positive:''

        };
        this.canvasRef = React.createRef();
      }

unLocked = false;
    /**
     * A semi-generic way to handle multiple lists. Matches
     * the IDs of the droppable container to the names of the
     * source arrays stored in the state.
     */
    id2List = {
        droppable: 'negative',
        droppable2: 'natural',
        droppable3: 'positive',
        droppable4: 'post01',
        droppable5: 'post02',
        droppable6: 'post00',
        droppable7: 'post11',
        droppable8: 'post12',
        
    };

    componentDidMount(){


        let ref = Firebase.database().ref('/clients/'+localStorage.getItem('companyId')+'/q-sort/users/'+localStorage.getItem('user-key')+'/saves/'+localStorage.getItem('save-key')+'/-2');
        ref.on('value' , snapshot => {
            let state = snapshot.val();
          this.setState({
              post01: state
          })
        })
        let ref1 = Firebase.database().ref('/clients/'+localStorage.getItem('companyId')+'/q-sort/users/'+localStorage.getItem('user-key')+'/saves/'+localStorage.getItem('save-key')+'/-1');
        ref1.on('value' , snapshot => {
            let state = snapshot.val();
          this.setState({
              post02: state
          })
        })

        let ref0 = Firebase.database().ref('/clients/'+localStorage.getItem('companyId')+'/q-sort/users/'+localStorage.getItem('user-key')+'/saves/'+localStorage.getItem('save-key')+'/0');
        ref0.on('value' , snapshot => {
            let state = snapshot.val();
          this.setState({
              post00: state
          })
        })
        let ref11 = Firebase.database().ref('/clients/'+localStorage.getItem('companyId')+'/q-sort/users/'+localStorage.getItem('user-key')+'/saves/'+localStorage.getItem('save-key')+'/1');
        ref11.on('value' , snapshot => {
            let state = snapshot.val();
          this.setState({
              post11: state
          })
        })

        let ref12 = Firebase.database().ref('/clients/'+localStorage.getItem('companyId')+'/q-sort/users/'+localStorage.getItem('user-key')+'/saves/'+localStorage.getItem('save-key')+'/2');
        ref12.on('value' , snapshot => {
            let state = snapshot.val();
          this.setState({
              post12: state
          })
        })
  
      }

    getList = id => this.state[this.id2List[id]];

    checkLength(){
        if(this.state.negative.length-1 < 1 && this.state.natural.length-1 < 1 && this.state.positive.length-1 < 1){
            this.setState({
                donesorting: true
            })
        }else{
            this.setState({
                donesorting: false
            })
        }
    }

    resetCards(){
        this.setState({
            negative: this.state.negativeBackup,
            natural: this.state.naturalBackup,
            positive: this.state.positiveBackup,
            post01: [
            ],
            post02: [
            ],
            post00: [
            ],
            post11: [
            ],
            post12: [
            ],
            errorMessage:''

        })
    }

    doneSorting(){
      
        


        Firebase.database().ref('/clients/'+localStorage.getItem('companyId')+'/q-sort/users/'+localStorage.getItem('user-key')+'/saves/'+localStorage.getItem('save-key')).update({

              whynegative: this.state.negative,
              whypositive: this.state.positive

      })

   
    }


    checkDropLenght(){
        console.log('hello')
      console.log(this.state.post01.length > 0 ? true:false)
    /*  for(let i =0; i<3;i++){
      console.log(this.state.post01.length)
    }
      this.setState({
        errorMessageActive:false,
         
      })
        if(this.state.post01.length > 1){
            this.setState({
                errorMessage:'De kolom -2 bevat teveel items, deze kolom mag maximaal 1 item bevatten.',
                errorMessageActive:true,
            })          
    
        }
        else if(this.state.post02.length  > 1){
            this.setState({
                errorMessage:'De kolom -1 bevat teveel items, deze kolom mag maximaal 2 item bevatten.',
                errorMessageActive:true,
            })          
        } 
        else if(this.state.post00.length  > 3){
            this.setState({
                errorMessage:'De kolom 0 bevat teveel items, deze kolom mag maximaal 3 item bevatten.',
                errorMessageActive:true,
            })          
        }  
        else if(this.state.post11.length > 2){
            this.setState({
                errorMessage:'De kolom 1 bevat teveel items, deze kolom mag maximaal 2 item bevatten.',
                errorMessageActive:true,
            })          
        }
        else if(this.state.post12.length > 1){
            this.setState({ 
                errorMessage:'De kolom 2 bevat teveel items, deze kolom mag maximaal 1 item bevatten.',
                errorMessageActive:true,
            })          
        }*/
       
       if(this.state.post01.length ==   Number(localStorage.getItem('amount')) ==  2 ? 1 :2 &&this.state.post02.length ==  Number(localStorage.getItem('amount')) ==  2 ? 2:3 && this.state.post00.length ==  Number(localStorage.getItem('amount')) ==  2 ? 3:5 &&this.state.post11.length ==  Number(localStorage.getItem('amount')) ==  2 ? 2:3 && this.state.post12.length ==  Number(localStorage.getItem('amount')) ==  2 ? 1:2){
            this.setState({
                afronden: true
            }) 
        }    
    }

    onDragEnd = result => {
        this.setState({
            errorMessage:'',
           
        })
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const negative = reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );

            


            let state = { negative };


            if (source.droppableId === 'droppable2') {
                state = { 
                    natural: negative ,
                
                };
                this.checkDropLenght();

            }

            if (source.droppableId === 'droppable3') {
                state = { positive: negative,

                };
                this.checkDropLenght();

            }


            if (source.droppableId === 'droppable4') {
                state = { post01: negative,};
                this.checkDropLenght();

            }
            if (source.droppableId === 'droppable5') {
                state = { post02: negative,

                };
                this.checkDropLenght();

            }
            if (source.droppableId === 'droppable6') {
                state = { post00: negative,};
                this.checkDropLenght();

            }
            if (source.droppableId === 'droppable7') {
                state = { post11: negative,

                };
                this.checkDropLenght();

            }
            if (source.droppableId === 'droppable8') {
                state = { post12: negative,

                };
                this.checkDropLenght();

            }

            

            this.setState(state);

        } else {
            const result = move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );

//Begin of if-s
if(source.droppableId === 'droppable' && destination.droppableId === 'droppable2' ){
    this.setState({
        negative: result.droppable,
        natural: result.droppable2,
        errorMessage:''

    });
    this.checkDropLenght();
    this.checkLength();
    }
else  if(source.droppableId === 'droppable2' && destination.droppableId === 'droppable' ){
    this.setState({
        negative: result.droppable,
        natural: result.droppable2,
        errorMessage:''
  
    });   
    this.checkDropLenght(); 
    this.checkLength();
}
    
    
else  if(source.droppableId === 'droppable' && destination.droppableId === 'droppable3' ){
    this.setState({
        negative: result.droppable,
        positive: result.droppable3,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable3' && destination.droppableId === 'droppable' ){
    this.setState({
        negative: result.droppable,
        positive: result.droppable3,
        errorMessage:''
  
});
this.checkDropLenght();
this.checkLength();
}
else  if(source.droppableId === 'droppable2' && destination.droppableId === 'droppable3' ){
    this.setState({
        natural: result.droppable2,
        positive: result.droppable3,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable3' && destination.droppableId === 'droppable2' ){
    this.setState({
        natural: result.droppable2,
        positive: result.droppable3,
        errorMessage:''
  
});
this.checkDropLenght();
this.checkLength();
}



else if(source.droppableId === 'droppable' && destination.droppableId === 'droppable4' ){
    this.setState({
        negative: result.droppable,
        post01: result.droppable4,
        errorMessage:''

    });
    this.checkDropLenght();
    this.checkLength();
    }
else  if(source.droppableId === 'droppable4' && destination.droppableId === 'droppable' ){
    this.setState({
        negative: result.droppable,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable2' && destination.droppableId === 'droppable4' ){
    this.setState({
        natural: result.droppable2,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable4' && destination.droppableId === 'droppable2' ){
    this.setState({
        natural: result.droppable2,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable3' && destination.droppableId === 'droppable4' ){
    this.setState({
        positive: result.droppable3,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable4' && destination.droppableId === 'droppable3' ){
    this.setState({
        natural: result.droppable3,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable5' && destination.droppableId === 'droppable4' ){
    this.setState({
        post02: result.droppable5,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable4' && destination.droppableId === 'droppable5' ){
    this.setState({
        post02: result.droppable5,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable6' && destination.droppableId === 'droppable4' ){
    this.setState({
        post00: result.droppable6,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable4' && destination.droppableId === 'droppable6' ){
    this.setState({
        post00: result.droppable6,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable7' && destination.droppableId === 'droppable4' ){
    this.setState({
        post11: result.droppable7,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable4' && destination.droppableId === 'droppable7' ){
    this.setState({
        post11: result.droppable7,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable8' && destination.droppableId === 'droppable4' ){
    this.setState({
        post12: result.droppable8,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable4' && destination.droppableId === 'droppable8' ){
    this.setState({
        post12: result.droppable8,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable' && destination.droppableId === 'droppable5' ){
    this.setState({
        negative: result.droppable,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable5' && destination.droppableId === 'droppable' ){
    this.setState({
        negative: result.droppable,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable2' && destination.droppableId === 'droppable5' ){
    this.setState({
        natural: result.droppable2,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable5' && destination.droppableId === 'droppable2' ){
    this.setState({
        natural: result.droppable2,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable3' && destination.droppableId === 'droppable5' ){
    this.setState({
        positive: result.droppable3,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable5' && destination.droppableId === 'droppable3' ){
    this.setState({
        positive: result.droppable3,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable6' && destination.droppableId === 'droppable5' ){
    this.setState({
        post00: result.droppable6,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable5' && destination.droppableId === 'droppable6' ){
    this.setState({
        post00: result.droppable6,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable7' && destination.droppableId === 'droppable5' ){
    this.setState({
        post11: result.droppable7,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable5' && destination.droppableId === 'droppable7' ){
    this.setState({
        post11: result.droppable7,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable8' && destination.droppableId === 'droppable5' ){
    this.setState({
        post12: result.droppable8,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable5' && destination.droppableId === 'droppable8' ){
    this.setState({
        post12: result.droppable8,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable' && destination.droppableId === 'droppable6' ){
    this.setState({
        negative: result.droppable,
        post00: result.droppable6,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable6' && destination.droppableId === 'droppable' ){
    this.setState({
        post00: result.droppable6,
        negative: result.droppable,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable2' && destination.droppableId === 'droppable6' ){
    this.setState({
        natural: result.droppable2,
        post00: result.droppable6,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable6' && destination.droppableId === 'droppable2' ){
    this.setState({
        post00: result.droppable6,
        natural: result.droppable2,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable3' && destination.droppableId === 'droppable6' ){
    this.setState({
        positive: result.droppable3,
        post00: result.droppable6,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable6' && destination.droppableId === 'droppable3' ){
    this.setState({
        post00: result.droppable6,
        positive: result.droppable3,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable7' && destination.droppableId === 'droppable6' ){
    this.setState({
        post11: result.droppable7,
        post00: result.droppable6,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable6' && destination.droppableId === 'droppable7' ){
    this.setState({
        post11: result.droppable7,
        post00: result.droppable6,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable8' && destination.droppableId === 'droppable6' ){
    this.setState({
        post12: result.droppable8,
        post00: result.droppable6,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable6' && destination.droppableId === 'droppable8' ){
    this.setState({
        post12: result.droppable8,
        post00: result.droppable6,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable' && destination.droppableId === 'droppable7' ){
    this.setState({
        negative: result.droppable,
        post11: result.droppable7, 
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable7' && destination.droppableId === 'droppable' ){
    this.setState({
        negative: result.droppable,
        post11: result.droppable7,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable2' && destination.droppableId === 'droppable7' ){
    this.setState({
        natural: result.droppable2,
        post11: result.droppable7,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable7' && destination.droppableId === 'droppable2' ){
    this.setState({
        natural: result.droppable2,
        post11: result.droppable7,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable3' && destination.droppableId === 'droppable7' ){
    this.setState({
        positive: result.droppable3,
        post11: result.droppable7,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable7' && destination.droppableId === 'droppable3' ){
    this.setState({
        positive: result.droppable3,
        post11: result.droppable7, errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable8' && destination.droppableId === 'droppable7' ){
    this.setState({
        post12: result.droppable8,
        post11: result.droppable7,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable7' && destination.droppableId === 'droppable8' ){
    this.setState({
        post12: result.droppable8,
        post11: result.droppable7,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable' && destination.droppableId === 'droppable8' ){
    this.setState({
        negative: result.droppable,
        post12: result.droppable8,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable8' && destination.droppableId === 'droppable' ){
    this.setState({
        negative: result.droppable,
        post12: result.droppable8,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable2' && destination.droppableId === 'droppable8' ){
    this.setState({
        natural: result.droppable2,
        post12: result.droppable8,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable8' && destination.droppableId === 'droppable2' ){
    this.setState({
        natural: result.droppable2,
        post12: result.droppable8,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable3' && destination.droppableId === 'droppable8' ){
    this.setState({
        positive: result.droppable3,
        post12: result.droppable8,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable8' && destination.droppableId === 'droppable3' ){
    this.setState({
        positive: result.droppable3,
        post12: result.droppable8,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}


            console.log("eerste na slepen" +result);


        
        //Rechter rij


        }
    };

    componentWillMount(){
        console.log(this.props.qsortdata);
    }



    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
     //   const isDropDisabled = this.state.selected.length > 3;
       // console.log('is die false of true:' + isDropDisabled);
        return (

            <Col lg="12" md="12" sm="12" className="mb-4">
                <Row>
                <Col sm="4">   <img src={localStorage.getItem('companyLogo')}  className="company-logo-left"/></Col>
                <Col sm="4" className="qsortdone">
           {/*} <Button variant="primary" className="button-restart" onClick={()=> this.resetCards()}>Kaartjes herstellen</Button>
            <Button variant="primary" className="button-sortdone" onClick={()=> this.checkDropLenght()}>Controleren</Button>*/}
          {this.state.negative != '' && this.state.positive !=''? <Link className="link-setup-afronden" onClick={()=> this.doneSorting()}to={{pathname: `/q-sort-done`,state: {questions: this.state.questions, key:localStorage.getItem('user-key')}}} >Afronden</Link> :null}
            </Col>
            </Row>
            <Row>
                <Col sm="4"></Col>
                <Col sm="4">
                {this.state.errorMessageActive ? 
              <div className="error-message-qsort">
                <p>{this.state.errorMessage}</p>
              </div>
              :
                <div></div>
              }
                </Col>
            </Row>
           
                                            <Row>

<Col lg="12" className="block-middle-stelling-qsort">
    <h5>{this.state.stelling}</h5>
</Col>


</Row>

<Row>
    <Col sm={5} className="text-areas-check">
        <label>Jouw toelichting waarom je de twee gekozen stellingen op -2 hebt gelegd: (verplicht)</label>
        <textarea value={this.state.negative} onChange={(event)=>this.setState({negative:event.target.value})}></textarea>
    </Col>
    <Col sm={5} className="text-areas-check">
        <label>Jouw toelichting waarom je de twee gekozen stellingen op +2 hebt gelegd:  (verplicht)</label>
        <textarea value={this.state.positive} onChange={(event)=>this.setState({positive:event.target.value})}></textarea>
    </Col>
</Row>
            <DragDropContext onDragEnd={this.onDragEnd}>
               
                
                <Row className="Drops">
                <Col lg="2" md="2" sm="2" className={Number(localStorage.getItem('amount')) ==  2 ? "mb-1 custom-Class position-negative one"  :"mb-1 custom-Class position-negative two"}>
                <div class="position-title"><h3>-2 | 2/{ 2-this.state.post01.length }<br/> (Minst mee eens) </h3></div>
                <Droppable isDragDisabled={true} droppableId="droppable4"  >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}>
                            {this.state.post01.map((item, index) => (
                                <Draggable 
                                isDragDisabled={true} 
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}>
                                            {item.vraag}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                {Number(localStorage.getItem('amount')) ==  2 ? 
                this.state.post01.length >= 2 ?   <span className="error-inside-qsort">De kolom -2 bevat teveel items, deze kolom mag maximaal 1 item bevatten.</span>: ''
                :
                this.state.post01.length >= 3 ?   <span className="error-inside-qsort">De kolom -2 bevat teveel items, deze kolom mag maximaal 2 items bevatten.</span>: ''
                }
                </Col>

                <Col  lg="2" md="2" sm="2"  className={Number(localStorage.getItem('amount')) ==  2 ?  "mb-1 custom-Class position-negative min-twee two":"mb-1 custom-Class position-negative min-twee three"}>
                <div class="position-title"><h3>-1 | 3/{ 3-this.state.post02.length } <br/><span className="whitetext">minst mee eens</span></h3></div>
                <Droppable isDragDisabled={true} droppableId="droppable5"   isDropDisabled={this.state.post02Open}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}>
                            {this.state.post02.map((item, index) => (
                                <Draggable
                                isDragDisabled={true} 
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}>
                                            {item.vraag}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                {Number(localStorage.getItem('amount')) ==  2 ? 
                this.state.post02.length >= 3 ?   <span className="error-inside-qsort">De kolom -1 bevat teveel items, deze kolom mag maximaal 2 items bevatten.</span>: ''
                :
                this.state.post02.length >= 4 ?   <span className="error-inside-qsort">De kolom -1 bevat teveel items, deze kolom mag maximaal 3 items bevatten.</span>: ''
                }
                </Col>


                <Col  lg="2" md="2" sm="2"  className={Number(localStorage.getItem('amount')) ==  2 ? "mb-1 custom-Class position-natural  three" : "mb-1 custom-Class position-natural  four" }>
                <div class="position-title"><h3>0 | 5/{ 5-this.state.post00.length } <br/> (Neutraal)</h3></div>
                <Droppable isDragDisabled={true} droppableId="droppable6"  isDropDisabled={this.state.post00Open} >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}>
                            {this.state.post00.map((item, index) => (
                                <Draggable
                                isDragDisabled={true} 
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}>
                                            {item.vraag}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                {Number(localStorage.getItem('amount')) ==  2 ? 
                this.state.post00.length >= 4 ?   <span className="error-inside-qsort">De kolom 0 bevat teveel items, deze kolom mag maximaal 3 items bevatten.</span>: ''
                :
                this.state.post00.length >= 6 ?   <span className="error-inside-qsort">De kolom 0 bevat teveel items, deze kolom mag maximaal 5 items bevatten.</span>: ''
                }
                </Col>

                <Col  lg="2" md="2" sm="2"  className={Number(localStorage.getItem('amount')) ==  2 ? "mb-1 custom-Class position-positive two" :"mb-1 custom-Class position-positive three"}>
                <div class="position-title"><h3>1 | 3/{ 3-this.state.post11.length }<br/><span className="whitetext">meest mee eens</span></h3></div>
                <Droppable isDragDisabled={true} droppableId="droppable7"   isDropDisabled={this.state.post11Open}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}>
                            {this.state.post11.map((item, index) => (
                                <Draggable
                                isDragDisabled={true} 
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}>
                                            {item.vraag}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                {Number(localStorage.getItem('amount')) ==  2 ? 
                this.state.post11.length >= 3 ?   <span className="error-inside-qsort">De kolom 1 bevat teveel items, deze kolom mag maximaal 2 items bevatten.</span>: ''
                :
                this.state.post11.length >= 4 ?   <span className="error-inside-qsort">De kolom 1 bevat teveel items, deze kolom mag maximaal 3 items bevatten.</span>: ''
                }
                </Col>

                <Col  lg="2" md="2" sm="2"  className={Number(localStorage.getItem('amount')) ==  2 ? "mb-1 custom-Class position-positive one": "mb-1 custom-Class position-positive two"}>
                <div class="position-title"><h3>2 | 2/{ 2-this.state.post12.length }<br/> (Meest mee eens)</h3></div>
                <Droppable isDragDisabled={true} droppableId="droppable8"  isDropDisabled={this.state.post12Open}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}>
                            {this.state.post12.map((item, index) => (
                                <Draggable
                                isDragDisabled={true} 
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}>
                                            {item.vraag}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                {Number(localStorage.getItem('amount')) ==  2 ? 
                this.state.post12.length >= 2 ?   <span className="error-inside-qsort">De kolom 2 bevat teveel items, deze kolom mag maximaal 1 item bevatten.</span>: ''
                :
                this.state.post12.length >= 3 ?   <span className="error-inside-qsort">De kolom 2 bevat teveel items, deze kolom mag maximaal 2 items bevatten.</span>: ''
                }
                
                </Col>
               
                              
                
                </Row>
                <Row className="row-colums" >
             
             
         </Row>
            </DragDropContext>
            
</Col>

        );
    }
};

export default QSortingQsortCheckScreen;
