import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Firebase from 'firebase';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';




class PoEditCom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploaded: false,
            title:'',
            notes:'',
            boardLocked: false
        };
        
    }

    componentDidMount(){
          //  this.getCategories();

            this.setState({
                title:this.props.postit.data.name,
                notes: this.props.postit.data.desc
            })


    }



    editPostIt(){
        this.setState({
            uploaded:true
        })
        Firebase.database().ref('/clients/-beifkwefiwebfnw/boards/'+localStorage.getItem('uniqueId')+'/settings/categories/'+this.props.postit.data.key).update({
            name: this.state.title,
            desc: this.state.notes
            
        }).then((data)=>{
            //success callback
            console.log('data ' , data)
        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        })
    
    }


  

    render() {
       
        return (
            <Row>
   
                   <Col lg="12" className="post-view-col">
                   <div className="post-it-view">
                        
                        <h4>Product informatie</h4>
                        <h6>Titel</h6>
                        <input type="text" placeholder={this.state.title} value={this.state.title} onChange={event => this.setState({ title: event.target.value, errorMessage:'',uploaded: false})}/>                    
                        <h6 className="post-it-textarew">Omschrijving</h6>
                        <textarea placeholder="Omschrijving" value={this.state.notes} onChange={event => this.setState({ notes: event.target.value, errorMessage:'',uploaded: false})}/>
{this.state.boardLocked ? null :
                        <span><Button className="ffort-button mt-10" onClick={()=>this.editPostIt()}>Bijwerken</Button> {this.state.uploaded ?<span className="post-it-update"> <i class="fas fa-check"></i>  Product is bijgewerkt.</span> :null}<br /></span>}
                        <span className="post-it-close" style={{marginTop:'3%'}}> <Link to={{pathname: `/apm-bord`,state: {}}}>Terug naar bord</Link></span> {this.state.deletedPost ?  <span className="post-it-delete"><a onClick={()=> this.deletedItem()}>Verplaats naar bureau</a></span> : null}
                    </div>
                   </Col>
   
                
            </Row>



        );
    }
};

export default PoEditCom;