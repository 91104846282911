import React, { Component } from 'react';

import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';



class LoginSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userUid:'',
            accesAplications:[]
        }

      }


      componentDidMount(){
        localStorage.removeItem("uniqueId");
        this.setState({
            userUid:Firebase.auth().currentUser.uid,
        })

        console.log(Firebase.auth().currentUser.uid)
            this.setState({accesAplications:[]})
            let userAcces = Firebase.database().ref('/users/'+Firebase.auth().currentUser.uid+'/accesto/');
            userAcces.once('value' , snapshot => {
                snapshot.forEach((childSnap) => {
                let state = childSnap.val();

                this.setState(prevState => ({
                
                    accesAplications: [...prevState.accesAplications, state],
                }));
             });
        })

        let Role = Firebase.database().ref('/users/'+Firebase.auth().currentUser.uid);
        Role.once('value' , snapshot => {
            let state = snapshot.val();

            localStorage.setItem('role', state.role)
    })
        
      }


          
    render() {
        return (
            <Row >
                <Col lg="2"></Col>
                <Col lg="8" className="col-height">
                    <div className="list-item-selection">
                        
                        <h4>Kies applicatie</h4>  {localStorage.getItem('role') == 'admin'? <Link className="align-button-right selection-button" to={{pathname: `/mijn-borden`}} >Naar Admin</Link>:null}
                        <br/>

                        {this.state.accesAplications.map((application,i)=>(
                            <li><span className="align-left ">{application.name}</span> <span className="align-right"><Link to={{pathname: `/mijn-bureau`,state: {clientId: application.clientId}}}>Bekijk</ Link></span></li>
                            ))}                       
                       
                    </div>
                </Col>
                <Col lg="2"></Col>
            </Row>         
        );
    }
};

export default LoginSelection;
