import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase, { storage } from 'firebase';
import { Dots } from 'react-activity';
import 'react-activity/dist/react-activity.css';

class SettingsQsortScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTwo:true,
            activeThree:true,
            activeFour:true,
            activeFive:true,
            activeSix:true,
            activeSeven:true,
            selectSteps: 0,
            stepsSet: false,
            maxQuestions: 0,
            categories:[],
            key:'',
            items:[
              {categoryName: 'HR',key:'', sets:[{setname:'Set1',setKey:'',questions:[{id:'0', categoryName:'', vraag:'hoe oud wordt Raoul?',setname:'', key:'', isDeleted:0 },]}]},
            ],
            sets:[],
            selectedSet: '',
            questions:[],
            newQuestion: '',
            createBoard: false,
            errorMessage:'',
            selectedCategory:'',
            selectedSets:'',
            item: 0,
            companyName: '',
            participants:'',
            stelling:'',
            image: null,
            progress:0,
            url:'',
            uploaded: false,
            uploading: false,
            companyText:'',
            amount:0,
            clients:[]
        };
      }



      handleChange(e){
        this.setState({
          image: e.target.files[0],
          errorMessageLogo:''
        })
      }

      handleUpload(){
        const {image} = this.state;
        this.setState({
          uploaded:true
        })

        if(image != null){
          const uploadTask = Firebase.storage().ref(`images/${image.name}`).put(image);
          uploadTask.on(
            'state_changed',
            snapshot => {
            },
            error => {
              console.log(error);
            },
            () => {
              Firebase.storage()
                .ref('images')
                .child(image.name)
                .getDownloadURL()
                .then(url => {
                  this.setState({
                    url: url,
                    uploading: true
                  })
                });
            }
          );
        }else{
          this.setState({
            errorMessageLogo: 'Er is geen foto geselecteert.',
            uploaded: false,
            uploading: false
          })
          
        }
    
        
      }
    amountOfSteps(amount){
        if(amount == 2){
            this.setState({
                activeTwo: false,
                activeThree: true,
                activeFour: true,
                activeFive: true,
                activeSix: true,
                activeSeven: true,
                maxQuestions: 9,
                amount: amount
            });
        }
        if(amount == 3){
            this.setState({
                activeTwo: true,
                activeThree: false,
                activeFour: true,
                activeFive: true,
                activeSix: true,
                activeSeven: true,
                maxQuestions: 15,
                amount: amount
            });
        }
        if(amount == 4){
            this.setState({
                activeTwo: true,
                activeThree: true,
                activeFour: false,
                activeFive: true,
                activeSix: true,
                activeSeven: true,
                maxQuestions: 25,
                amount: amount
            });
        }
        if(amount == 5){
            this.setState({
                activeTwo: true,
                activeThree: true,
                activeFour: true,
                activeFive: false,
                activeSix: true,
                activeSeven: true,
                maxQuestions: 34,
                amount: amount
            });
        }
        if(amount == 6){
          this.setState({
              activeTwo: true,
              activeThree: true,
              activeFour: true,
              activeFive: true,
              activeSix: false,
              activeSeven: true,
              maxQuestions: 49,
              amount: amount
          });
      }
      if(amount == 7){
        this.setState({
            activeTwo: true,
            activeThree: true,
            activeFour: true,
            activeFive: true,
            activeSix: true,
            activeSeven: false,
            maxQuestions: 64,
            amount: amount
        });
    }

        this.setState({
            selectSteps: amount,
            stepsSet: true
            
        })

        console.log(this.state.selectSteps);
    }

    changeCategory(event){
      let getevent =  event.target.value;
      this.setState({
        sets:[],
        selectedCategory: getevent
      })
      let sets = Firebase.database().ref('/category/'+ getevent + '/sets');
      sets.on('value' , snapshot => {
        snapshot.forEach((childSnap) => {
        let state = childSnap.val();
       
         
          var newelement = {setname: state.setname, categoryName: state.categoryName, setKey: state.setKey};
          this.setState(prevState => ({
            
            sets: [...prevState.sets, newelement],
          }));
   

        });
          
      })

    }

    handleSignout(){

  Firebase.auth().signOut();



    }

    changeSets(event){
      let getevent =  event.target.value;
      console.log(getevent);
      this.setState({
        questions:[],
        selectedSet: getevent
      })
      let sets = Firebase.database().ref('/category/'+ this.state.selectedCategory + '/sets/'+ getevent+'/questions/');
      sets.on('value' , snapshot => {
        snapshot.forEach((childSnap) => {
        let state = childSnap.val();
        console.log("de vraag: "+state.vraag);
         
          var newelement = {setname: state.setname, categoryName: state.categoryName, key: state.key, isDeleted: state.isDeleted, vraag: state.vraag, id: state.id};
          this.setState(prevState => ({
            
            questions: [...prevState.questions, newelement],
          }));
   

        });
          
      })
      {/*
      this.state.sets.forEach(i =>{
        if( i.name == getevent){
            this.setState({
              questions: i.questions,
            })
        }
      })
 */}
    }

    addQuestion(){
      console.log(this.state.newQuestion);
      var newelement = {key: this.state.selectedSet, isDeleted: 0, vraag: this.state.newQuestion, id: 'item-'+String(this.state.questions.length+1)};
      this.setState(prevState => ({
          questions: [...prevState.questions, newelement],
          newQuestion: '',
          errorMessage: '',
        }));


    }

    deleteQuestion(key){
      console.log(this.state.questions[key]);
      const questions = this.state.questions;
      questions.splice(key, 1);
      this.setState({ questions });
      this.setState({
        errorMessage: ''
      });
    }

    changeQuestion(key){
      console.log(String(key));
      this.state.questions.forEach(i =>{
        if( i.id == String(key)){
          var newelement = {id:'0', nummer:1, vraag:'hoe oud wordt een salamander1?' };
            console.log('match');
        }
      })
    }

    checkQuestions(){
      if(this.state.questions.length === this.state.maxQuestions && this.state.companyName != '' && this.state.stelling != ''&& this.state.uploading == true && this.state.companyText !=''){
        this.saveInfo();
        this.setState({
          createBoard: true,
          errorMessage: ''
        })
      }else if( this.state.questions.length < this.state.maxQuestions){
        this.setState({
          createBoard: false,
          errorMessage: 'Er zijn helaas te weinig vragen geselecteerd, voeg meer vragen toe en probeer het opnieuw'
        })
      }
      else if( this.state.questions.length > this.state.maxQuestions){
        this.setState({
          createBoard: false,
          errorMessage: 'Er zijn helaas te veel vragen geselecteerd, verwijder vragen en probeer het opnieuw'
        })
      }
      else if( this.state.companyName == '' ){
        this.setState({
          createBoard: false,
          errorMessage: 'Vul alle algemene instellingen in om verder te gaan'
        })
      }
      else if( this.state.stelling == ''){
        this.setState({
          createBoard: false,
          errorMessage: 'Vul een stelling in!'
        })
      }
      else if( this.state.uploading == false){
        this.setState({
          createBoard: false,
          errorMessage: 'Er is geen bedrijfslogo geüpload.'
        })
      }
      else if( this.state.companyText == ''){
        this.setState({
          createBoard: false,
          errorMessage: 'Er is geen introductie tekst ingevoerd.'
        })
      }
    }

    getClients(){
      let ref = Firebase.database().ref('/clients/');
      ref.on('value' , snapshot => {
          this.setState({products:[]})
         
          snapshot.forEach((childSnap) => {
          let state = childSnap.val();
          console.log("resources:"+state);
           this.setState(prevState => ({
              clients: [...prevState.clients, state]
            }))
          })
        })
    }

    componentDidMount(){

      this.getClients();
      /*let ref = Firebase.database().ref('/category');
      ref.on('value' , snapshot => {
          snapshot.forEach((childSnap) => {
          let state = childSnap.val();
          console.log('firebase output:'+state)
          var newelement = {name: state.name, key: state.key};
          this.setState(prevState => ({
            
            categories: [...prevState.categories, newelement],
          }));
          console.log('firebase'+state.name);
          });
      })*/

    }

    herstelInfo(){
      this.setState({
        companyName:'',
        participants: ''
      })
    }

    saveInfo(){
      var nd = new Date();
      var d = nd.getDate();
      var m = nd.getMonth() +1;
      var y = nd.getFullYear();

      var dateStamp = d+'-'+m+'-'+y;
      
      var newPostKey = Firebase.database().ref().child('/clients/'+this.state.companyName+'/q-sort/sessions/').push().key;
        Firebase.database().ref('/clients/'+this.state.companyName+'/q-sort/sessions/'+newPostKey).set({
          amount: this.state.amount,
          questions: this.state.questions,
          stelling: this.state.stelling,
          logo: this.state.url,
          key:newPostKey,
          companyText: this.state.companyText
      })

      this.setState({
        key: newPostKey,
      })
    }
    






    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
     //   const isDropDisabled = this.state.selected.length > 3;
       // console.log('is die false of true:' + isDropDisabled);
        return (

            <Row>

            <Col lg="12">
              <h6 className="titles-setup">Selecteer bord</h6>
            
            </Col>
      
              <Col lg="2">
              {this.state.activeTwo ?  
                <div  className="settingsBlocks">
                    <img src={require('../../assets/images/2-stappen.png')}  className="image-steps"/>
                    <h5  className="image-steps-title">2 Stappen  (9 stuks)</h5>
                    <a onClick={() => this.amountOfSteps(2)}>Selecteer</a>
                  </div>
                :
                <div  className="active-block">
                    <img src={require('../../assets/images/2-stappen.png')}  className="image-steps"/>
                    <h5  className="image-steps-title">2 Stappen  (9 stuks)</h5>
                    <a onClick={() => this.amountOfSteps(2)}>Selecteer</a>
                  </div>
                }
              </Col>
              <Col lg="2">
              {this.state.activeThree ?  
                <div  className="settingsBlocks">
                    <img src={require('../../assets/images/2-stappen.png')}  className="image-steps"/>
                    <h5  className="image-steps-title">2 Stappen (15 stuks)</h5>
                    <a onClick={() => this.amountOfSteps(3)}>Selecteer</a>
                  </div>
                :
                <div  className="active-block">
                    <img src={require('../../assets/images/2-stappen.png')}  className="image-steps"/>
                    <h5  className="image-steps-title">2 Stappen (15 stuks)</h5>
                    <a onClick={() => this.amountOfSteps(3)}>Selecteer</a>
                  </div>
                }
              </Col>
              {/*}
              <Col lg="2">
              {this.state.activeFour ?  
                <div  className="settingsBlocks">
                    <img src={require('../../assets/images/4-stappen.png')}  className="image-steps"/>
                    <h5  className="image-steps-title">4 Stappen</h5>
                    <a disabled onClick={() => this.amountOfSteps(4)}>Selecteer</a>
                  </div>
                :
                <div  className="active-block">
                    <img src={require('../../assets/images/4-stappen.png')}  className="image-steps"/>
                    <h5  className="image-steps-title">4 Stappen</h5>
                    <a disabled onClick={() => this.amountOfSteps(4)}>Selecteer</a>
                  </div>
                }
              </Col>
              <Col lg="2">
                {this.state.activeFive ?  
                <div  className="settingsBlocks">
                    <img src={require('../../assets/images/5-stappen.png')}  className="image-steps"/>
                    <h5  className="image-steps-title">5 Stappen</h5>
                    <a  disabled onClick={() => this.amountOfSteps(5)}>Selecteer</a>
                  </div>
                :
                <div  className="active-block">
                    <img src={require('../../assets/images/5-stappen.png')}  className="image-steps"/>
                    <h5  className="image-steps-title">5 Stappen</h5>
                    <a disabled onClick={() => this.amountOfSteps(5)}>Selecteer</a>
                  </div>
                }
              </Col>
              <Col lg="2">
                {this.state.activeSix ?  
                <div  className="settingsBlocks">
                    <img src={require('../../assets/images/6-stappen.png')}  className="image-steps"/>
                    <h5  className="image-steps-title">6 Stappen</h5>
                    <a disabled onClick={() => this.amountOfSteps(6)}>Selecteer</a>
                  </div>
                :
                <div  className="active-block">
                    <img src={require('../../assets/images/6-stappen.png')}  className="image-steps"/>
                    <h5  className="image-steps-title">6 Stappen</h5>
                    <a disabled onClick={() => this.amountOfSteps(6)}>Selecteer</a>
                  </div>
                }
              </Col>
              <Col lg="2">
                {this.state.activeSeven ?  
                <div  className="settingsBlocks">
                    <img src={require('../../assets/images/7-stappen.png')}  className="image-steps"/>
                    <h5  className="image-steps-title">7 Stappen</h5>
                    <a disabled onClick={() => this.amountOfSteps(7)}>Selecteer</a>
                  </div>
                :
                <div  className="active-block">
                    <img src={require('../../assets/images/7-stappen.png')}  className="image-steps"/>
                    <h5  className="image-steps-title">7 Stappen</h5>
                    <a disabled onClick={() => this.amountOfSteps(7)}>Selecteer</a>
                  </div>
                }
              </Col>*/}
            
              {this.state.selectSteps ? 
    
              <Col lg="12">
                <Row>
            <Col lg="6" className="bord-settings">
              <h6 className="titles-setup">Bord instellingen</h6>
              <input type="text" placeholder="Stelling.." value={this.state.stelling} onChange={event => this.setState({ stelling: event.target.value, errorMessage:''})}/> <br /><br />
       
              <h6 className="titles-setup">Selecteer bestaande klant</h6><br/>
              <select value={this.state.companyName} onChange={event => this.setState({ companyName: event.target.value, errorMessage:''})}>
              <option class="placeholder" selected disabled value="">Selecteer een klant</option>
                {this.state.clients.map((client,i)=>(
                  <option index={i} value={client.id}>{client.name}</option>
                ))}
              </select><br/><br/>
              {/*<select id="cars" name="cars" className="select-box-bord" onChange={(event) => this.changeCategory(event)}>
              <option value="" disabled selected>Selecteer categorie</option>
                {
              this.state.categories.map((item, i) => (
                <option value={item.key} key={i}>{item.name}</option>
                  ))
                }
              </select><br /><br/>
              <select id="cars" name="cars" className="select-box-bord" onChange={(event) => this.changeSets(event)} placeholder="Selecteer set">
              <option value="" disabled selected>Selecteer set</option>
              {
              this.state.sets.map((set, i) => (
                <option value={set.setKey} key={i}>{set.setname}</option>
                  ))
                }
              </select><br /><br/>*/}
             {/*  <Button variant="primary" className="button-setup" onClick={()=> this.addQuestions()}>Voeg toe</Button>*/}
            </Col>
            <Col lg="6" className="bord-settings">
              <h6 className="titles-setup">Bedrijfsinstellingen</h6>

              {this.state.uploaded ? this.state.uploading ? <p className="check-positive"> <i class="fas fa-check"></i> Logo geüpload!</p>: <Dots /> :
              <div>
              <input type="file" onChange={(event) => this.handleChange(event)} /><br /><br />
              <textarea placeholder="Gepersonaliseerde tekst.." value={this.state.companyText} onChange={event => this.setState({ companyText: event.target.value, errorMessage:''})}/> <br /><br />
              <button onClick={() => this.handleUpload()} className="button-setup-company">Gegevens verwerken</button></div>}<br /><br />
              {this.state.errorMessageLogo ? 
              <div className="error-message-image">
                <p>{this.state.errorMessageLogo}</p>
              </div>
              :
                <div></div>
              }
              
             {/* <Button variant="primary" className="button-setup"  onClick={()=> this.herstelInfo()}>Herstel</Button> <Button variant="primary" className="button-setup">Opslaan</Button>*/}
            </Col>


              {/* <Col lg="6" className="bord-settings">
              
              
           <input type="text" placeholder="Naam van bedrijf.." value={this.state.companyName} onChange={event => this.setState({ companyName: event.target.value, errorMessage:''})}/> <br /><br />
              <input type="text" placeholder="Aantal deelnemers" value={this.state.participants} onChange={event => this.setState({ participants: event.target.value, errorMessage:''})}/><br /><br />
             </Col>*/}
             <Col lg="6" className="bord-settings">
              <h6 className="titles-setup">Nieuwe vraag aanmaken</h6>
              <input type="text" placeholder="Extra vraag..." value={this.state.newQuestion} onChange={event => this.setState({ newQuestion: event.target.value, errorMessage:''})}/> <br /><br />
              <Button variant="primary" className="button-setup" onClick={()=>this.addQuestion()}>Voeg toe</Button>
            </Col>

            <Col lg="6" className="bord-settings bottom-col">
              <h6 className="titles-setup">Geselecteerde vragen | {this.state.questions.length} / {this.state.maxQuestions} </h6>
              {
                this.state.questions.filter( x => !x.isDeleted).map((question, i) => (
                    <li key={i}><span className="question-list">{question.vraag}</span>  <span className="see-list-delete"><a onClick={() => this.deleteQuestion(i)}><i class="far fa-trash-alt"></i></a></span> {/*<span className="see-list-edit"><a onClick={() => this.changeQuestion(question.id)}><i class="far fa-edit"></i></a></span>*/}</li>
                ))
              }

               {this.state.createBoard ?   <span className="url-q-sort"> De url is:<br/> <a href={"toolbox.f-fort.nl/q-sort?company="+this.state.companyName+"&id="+this.state.key} >toolbox.f-fort.nl/q-sort?company={this.state.companyName}&id={this.state.key} </a> </span> : <Button variant="primary" className="button-setup" onClick={()=> this.checkQuestions()}>Bord compleet</Button> }
              {this.state.errorMessage ? 
              <div className="error-message">
                <p>{this.state.errorMessage}</p>
              </div>
              :
                <div></div>
              }
            </Col>
            </Row>
            </Col>
            :
              <Col></Col>
            }                         
  


              </Row>
                         

                  
        );
    }
};

export default SettingsQsortScreen;
