import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toast, { Toaster } from 'react-hot-toast';

const notify = () => toast('Je wijzigingen zijn opgeslagen!');

class QArchiveSeenScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id:'',
            productDesc:'',
            image:[],
            images:[],
            imagesNew:[],
            catsNew:[],
            tagsNew:[],
            uploading:false,
            uploaded:false,
            url:'',
            imageAvailable: false,
            activeVariations: false,
            cat:'',
            categories:[],
            catActive:false,
            tag:'',
            tags:[],
            tagActive:false,
            clientUUID:'-ohlnfwebk',
            name:'',
            city:'',
            stock:0,
            variant:'selected',
            color:false,
            weight:false,
            quantity:false,
            measure:false,
            addVariantText:'',
            addVariantPrice:'',
            addVariantStock:0,
            variations:[],
            changeVariantText:'',
            changeVariantPrice:'',
            changeVariantStock:0,
            changes: false,
            sessions:[],
            users:[]
        };
    }

    

    

    



    componentWillMount() {
        this.setState({
            clientUUID: Firebase.auth().currentUser.uid,
            name:this.props.data.data.name
        })

        let ref = Firebase.database().ref('/clients/'+this.props.data.data.id+'/q-sort/sessions/');
        ref.on('value' , snapshot => {
            this.setState({sessions:[]})
           
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            console.log("resources:"+state);
             this.setState(prevState => ({
                        sessions: [...prevState.sessions, state]
                    })) 


        })
        
    });

    let refusers = Firebase.database().ref('/clients/'+this.props.data.data.id+'/q-sort/users/');
    refusers.on('value' , snapshot => {
        this.setState({users:[]})
       
        snapshot.forEach((childSnap) => {
        let state = childSnap.val();
        console.log("resources:"+state);
         this.setState(prevState => ({
                    users: [...prevState.users, state]
                })) 


    })
    
});

      
//console.log(this.props.data.data.productDesc)

//console.log(this.props.data.data.variant);

       

       
       
    }

    

    

    render() {
        return (
            <div>
                <Toaster />
            <Row className="col-sm-12">
                <Col sm={12} className="products-actions">
                    <Link to={{pathname: `/instellingen-q-sort`}}>Terug</Link>
                     {/*<Button className="main-save-button" onClick={()=>this.changeProduct()}>Opslaan</Button>
                   <Link className="align-button-right">Product toevoegen</Link>*/}
                </Col>
                <Col sm={8} className="colum-left">
                    <Row>
                        <Col sm={12} className="add-product-colum q-sort-back">
                            <label>Bedrijfsnaam</label><br/>
                            <input disabled type="text" value={this.state.name} onChange={(event)=>this.setState({name: event.target.value,changes:true})}/><br/><br/>
                           
                            
                        </Col>
                      
                        <Col sm={12} className="add-product-colum q-sort-back">
                            <label>URL's</label><br/>
                            <ul>
                            {this.state.sessions.map((session)=>(
                                <li>url is: toolbox.f-fort.nl/q-sort?company={this.props.data.data.id}&id={session.key}   <Link className="link-setup-changes" to={{pathname: `/archief-q-sort-aanpassen`,state: {id:this.props.data.data.id, key: session.key}}} >Bekijken</Link></li>
                            ))}
                            </ul>
                           
                            
                        </Col>
                        <Col sm={12} className="add-product-colum q-sort-back">
                            <label>Gebruikers ingevuld</label><br/>
                            <ul>
                            {this.state.users.map((user)=>(
                                <li>{user.name},{user.mail}</li>
                            ))}
                            </ul>
                           
                            
                        </Col>
                        <Col sm={12} className="last-col add-product-colum">
                        < hr/>
                       {/* <Button onClick={()=>this.changeProduct()}>Opslaan</Button>*/}
                        </Col>

                    </Row>
                </Col>
            
                <Col sm={4}>
                    <Row>
                        {/*<Col sm={12} className="add-product-colum right-product-add-colom">
                            <label>Productstatus</label><br/>
                            <select value={this.state.status} onChange={(event)=>this.setState({status: event.target.value,changes:true})}>
                            <option value="selected" selected="selected" disabled="disabled">Selecteer een status</option>
                                <option value="concept">Concept</option>
                                <option value="active">Actief</option>
                            </select>
                            
                            </Col>*/}
                       
                      
                    </Row>
                </Col>
   
            </Row>
            {this.state.changes?
            <Row className="changed-row">
                <Col sm={12} className="changed-col">
                    <p>Er zijn wijzigingen aangebracht. Vergeet niet om het product op te slaan.</p>
                    <Button  className="changed-button" onClick={()=>this.changeProduct()}>Opslaan</Button>
                </Col>
            </Row>:null}
            </div>



        );
    }
};

export default QArchiveSeenScreen;