import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import ProductsIndex from "../../components/products";
import QArchiveScreen from "../../components/qsort/QArchiveScreen";



const QArchiveView = () => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Q-sort Archief" subtitle="F-Fort" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <QArchiveScreen/>
    
  </Container>
);

export default QArchiveView;
