import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';




class StartQsortScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questions: [],
            key: '',
            user: 0,
            participants: 0,
            logo: '',
            loading: false,
            companyText:'',
            name:'',
            mail:'',
            id:'',
            stelling:''
     

        };

      }




      componentWillMount(){
        var nd = new Date();
        var d = nd.getDate();
        var m = nd.getMonth() +1;
        var y = nd.getFullYear();
        const query = new URLSearchParams(this.props.id)
        this.setState({
            id: query.get('id')

        })

        localStorage.setItem('questionId',query.get('id'))
        localStorage.setItem('companyId',query.get('company'))

        console.log(query.get('id'))

        console.log(query.get('company'))
  
        var dateStamp = d+'-'+m+'-'+y;
        var keyCat = Firebase.database().ref().child('/clients/'+query.get('company')+'/q-sort/users/').push().key;
this.setState({
    key: keyCat
})

        let ref = Firebase.database().ref('/clients/'+query.get('company')+'/q-sort/sessions/'+query.get('id') );
        ref.on('value' , snapshot => {

            let state = snapshot.val();

            this.setState({
                questions: state.questions,
                logo: state.logo,
                companyText: state.companyText,
                stelling: state.stelling
            })
            localStorage.setItem('amount', state.amount)
            localStorage.setItem('companyLogo', state.logo)
            console.log(this.state.questions)
            console.log(state.stelling)
        })
      }

      saveName(){
        const query = new URLSearchParams(this.props.id)
        //var keyCat = Firebase.database().ref().child('/clients/-qifwbefweifb/q-sort/users/').push().key;
        Firebase.database().ref('/clients/'+query.get('company')+'/q-sort/users/'+this.state.key).set({
       
            id: this.state.key,
            name: this.state.name,
            mail:this.state.mail,
        
    
        }).then((data)=>{
         
        

        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        })
      }
      
    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
     //   const isDropDisabled = this.state.selected.length > 3;
       // console.log('is die false of true:' + isDropDisabled);
        return (
        <div>


           <Row>
            <Col lg="2"></Col>
            <Col lg="8" className="start-block">
            <Row>
     
                <Col lg="12" className="block-middle">
                    <img src={require('../../assets/images/list-check.png')}  className="image-check"/>
                    <img src={localStorage.getItem('companyLogo')}  className="company-logo-block"/>
                </Col>

                <Col lg="12" className="block-middle-text">
                    <h6 className="titles-setup">Klaar om te starten!</h6>
                    <p>{this.state.companyText}</p>
                    
                    <label>Je naam</label><br/>
                    <input type="text" value={this.state.name} onChange={(event)=>this.setState({name: event.target.value})} />
                    <br/><br/>
                    <label>Je E-mail</label><br/>
                    <input type="text" value={this.state.mail} onChange={(event)=>this.setState({mail: event.target.value})} />  <br/><br/><br/>
                    {this.state.mail !='' ?
                    <Link className="link-setup" onClick={()=>this.saveName()} to={{pathname: `/q-sort-sorting`,state: {questions: this.state.questions, key: this.state.key,stelling:this.state.stelling}}} >Beginnen</Link>:null}
                </Col>
     
            </Row>
                

               
         
                
        
            </Col>
            <Col lg="2"></Col>
          </Row>
  
        </div>
        );
    }
};

export default StartQsortScreen;
