import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as firebase from 'firebase';
import 'firebase/auth';
import * as serviceWorker from './serviceWorker';
import {AuthProvider} from "./Auth";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"



//dev
var config ={
  apiKey: "AIzaSyDApGhAAkq0wNgRFHfkkxTz3Lp0ocK55Dk",
  authDomain: "f-fort-tool-2.firebaseapp.com",
  databaseURL: "https://f-fort-tool-2-default-rtdb.firebaseio.com",
  projectId: "f-fort-tool-2",
  storageBucket: "f-fort-tool-2.appspot.com",
  messagingSenderId: "1071331749719",
  appId: "1:1071331749719:web:af55c63209e81056311812"
}
firebase.initializeApp(config);


ReactDOM.render(
<AuthProvider>
<App />
</AuthProvider>

, 
document.getElementById('root'));


