import React from "react";
import { Container, Row, Col } from "shards-react";
import BoardsIndex from "../../components/boards";

import PageTitle from "../../components/common/PageTitle";
import OrdersIndex from "../../components/orders";
import UsersIndex from "../../components/users";



const UsersView = () => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Gebruikers" subtitle="F-Fort" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <UsersIndex />
    
  </Container>
);

export default UsersView;
