import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import { Dots } from 'react-activity';
import 'react-activity/dist/react-activity.css';




class EndQsortScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questions: this.props.qsortdata.questions,
            key: this.props.qsortdata.key,
            logo:'',
            user: 0,
            participants: 0,
            doneQ: false,
            companyName: '',
            results: [],
            selectedSet:'',
            stelling:'',
            loading: false,
            comments: '',
            uploaded: false,
            uploading: false,
            companyText:''
        };
      }


      checkParticipants(user, participants){
        console.log("numeroo:"+parseInt(participants));

        console.log("input:"+ user +'/'+participants);
        if(user >= participants ){
            console.log("hello world");
            this.setState({
                doneQ: true
            })
        }
      }

      nextParticipant(){
        var nd = new Date();
        var d = nd.getDate();
        var m = nd.getMonth() +1;
        var y = nd.getFullYear();
  
        var dateStamp = d+'-'+m+'-'+y;
        let newUser = this.state.user +1;
     /* Firebase.database().ref('/'+dateStamp+'/'+this.state.key).update({
        user: newUser,
        
    }).then((data)=>{
        //success callback
        console.log('data ' , data)
    }).catch((error)=>{
        //error callback
        console.log('error ' , error)
    })*/
      }


      componentDidMount(){
        var nd = new Date();
        var d = nd.getDate();
        var m = nd.getMonth() +1;
        var y = nd.getFullYear();
  
        var dateStamp = d+'-'+m+'-'+y;

    
         

     
  
      }

      addComment(){

        this.setState({
          uploaded: true,
        })
        var nd = new Date();
        var d = nd.getDate();
        var m = nd.getMonth() +1;
        var y = nd.getFullYear();
  
        var dateStamp = d+'-'+m+'-'+y;

        var newPostKey = Firebase.database().ref().child('/clients/'+localStorage.getItem('companyId')+'/q-sort/users/'+this.state.key).push().key;  
        Firebase.database().ref('/clients/'+localStorage.getItem('companyId')+'/q-sort/users/'+this.state.key).update({
                comment: this.state.comments,


      })

        this.setState({
          uploading: true
        })
  
      }

      shutdownApp(){
        window.open("", "_self");
        window.close();
      }
      
    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
     //   const isDropDisabled = this.state.selected.length > 3;
       // console.log('is die false of true:' + isDropDisabled);
        return (
          <div>
          
            <Row>
            <Col lg="2"></Col>
            <Col lg="8" className="end-block">
            <Row>
     
                <Col lg="12" className="block-middle">
                    <img src={require('../../assets/images/list-check.png')}  className="image-check"/>
                </Col>

                <Col lg="12" className="block-middle-text">
                    <h6 className="titles-setup">Je gegevens zijn opgeslagen!</h6>
                    <p>Bedankt dat je de Q-sort naar waarheid hebt ingevult. Wij gaan ermee aan de slag om alle gevens juist te verwerken. Je kunt nu het scherm afsluiten.</p>
                    

                  {this.state.uploaded ? this.state.uploading ? <p className="check-positive"> <i class="fas fa-check"></i> Je opmerking is opgeslagen <br/><br/>  <Link className="link-setup-afronden small" to={{pathname: 'q-sort?company='+localStorage.getItem('companyId')+'&id='+localStorage.getItem('questionId')+''}} >Afsluiten</Link></p>: <Dots /> :
              <div className="comment-section">
            <label>Heb je nog opmerkingen?</label><br /> <br /> 
            <textarea placeholder="Opmerkingen" value={this.state.comments} onChange={event => this.setState({ comments: event.target.value, errorMessage:''})}/> <br /><br />
              <button onClick={() => this.addComment()} className="link-setup-comment">Opmerking opslaan</button></div>}<br /> <br /> 
                   
      {/*}  {this.state.doneQ ?<div><Link className="link-setup" to={{pathname: `/`}} >Afsluiten</Link> <br /> <br /> <Link className="add-participant" to={{pathname: `/q-sort-start`,state: {questions: this.state.questions, key: this.state.key}}} onClick={()=> this.nextParticipant()}>Extra kandidaat</Link></div>:<Link className="link-setup" to={{pathname: `/q-sort-start`,state: {questions: this.state.questions, key: this.state.key}}} onClick={()=> this.nextParticipant()}>Volgende kandidaat</Link>}*/}
                </Col>
     
            </Row>
                

               
         
                
        
            </Col>
            <Col lg="2"></Col>
          </Row>
          </div>
        );
    }
};

export default EndQsortScreen;
