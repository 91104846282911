import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';



// fake data generator
const getItems = (count, offset = 0) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k + offset}`,
        content: `item ${k + offset}`
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid,
    width: 250
});

class QSortingQsortScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            negative: this.props.qsortdata.disagrees,
            natural: this.props.qsortdata.neutrals,
            positive: this.props.qsortdata.agrees,
            negativeBackup: this.props.qsortdata.disagrees,
            naturalBackup: this.props.qsortdata.neutrals,
            positiveBackup: this.props.qsortdata.agrees,
            questions: this.props.qsortdata.questions,
           logo:'',
            key:this.props.qsortdata.key,
            post01: [
            ],
            post02: [
            ],
            post00: [
            ],
            post11: [
            ],
            post12: [
            ],
            user:0,
            stelling:'',
            isUnlocked: false,
            donesorting: false,
            errorMessage:'',
            afronden: false,
            loading: false,
            post01Open:false,
            post02Open:false,
            post00Open:false,
            post11Open:false,
            post12Open:false,
            errorMessageActive:false,
            instruction:false

        };
        this.canvasRef = React.createRef();
      }

unLocked = false;
    /**
     * A semi-generic way to handle multiple lists. Matches
     * the IDs of the droppable container to the names of the
     * source arrays stored in the state.
     */
    id2List = {
        droppable: 'negative',
        droppable2: 'natural',
        droppable3: 'positive',
        droppable4: 'post01',
        droppable5: 'post02',
        droppable6: 'post00',
        droppable7: 'post11',
        droppable8: 'post12',
        
    };

    componentDidMount(){
        var nd = new Date();
        var d = nd.getDate();
        var m = nd.getMonth() +1;
        var y = nd.getFullYear();
  
        var dateStamp = d+'-'+m+'-'+y;

        console.log(this.props.qsortdata.key)
        this.setState({
           key: this.props.qsortdata.key
        })

        /*let ref = Firebase.database().ref('/' +dateStamp+'/'+this.state.key);
        ref.on('value' , snapshot => {

            let state = snapshot.val();

            this.setState({
                user: state.user,
                stelling: state.stelling,
                logo: state.logo,
                loading: true
            })
            
            
          
        })*/
  
      }

    getList = id => this.state[this.id2List[id]];

    checkLength(){
        if(this.state.negative.length-1 < 1 && this.state.natural.length-1 < 1 && this.state.positive.length-1 < 1){
            this.setState({
                donesorting: true
            })
        }else{
            this.setState({
                donesorting: false
            })
        }
    }

    resetCards(){
        this.setState({
            negative: this.state.negativeBackup,
            natural: this.state.naturalBackup,
            positive: this.state.positiveBackup,
            post01: [
            ],
            post02: [
            ],
            post00: [
            ],
            post11: [
            ],
            post12: [
            ],
            errorMessage:''

        })
    }

    doneSorting(){
        console.log("-2 output:"+this.state.post01);
        console.log("-1 output:"+this.state.post02);
        console.log("0 output:"+this.state.post00);
        console.log("1 output:"+this.state.post11);
        console.log("2 output:"+this.state.post12);
        var nd = new Date();
        var d = nd.getDate();
        var m = nd.getMonth() +1;
        var y = nd.getFullYear();
  
        var dateStamp = d+'-'+m+'-'+y;

    var newPostKey = Firebase.database().ref().child('/clients/'+localStorage.getItem('companyId')+'/q-sort/users/'+this.state.key+'/saves/').push().key;  
        Firebase.database().ref('/clients/'+localStorage.getItem('companyId')+'/q-sort/users/'+this.state.key+'/saves/'+newPostKey).set({

                '-2': this.state.post01,
                '-1':  this.state.post02,
                '0': this.state.post00,
                '1':this.state.post11,
                '2': this.state.post12,
                key: newPostKey,
                date:dateStamp,
                sessionId:localStorage.getItem('questionId')

      })


      localStorage.setItem('user-key',this.state.key);
      localStorage.setItem('save-key',newPostKey)
    }


    checkDropLenght(){
        console.log('hello')
        this.addMarginTop();
      console.log(this.state.post01.length > 0 ? true:false)
    /*  for(let i =0; i<3;i++){
      console.log(this.state.post01.length)
    }
      this.setState({
        errorMessageActive:false,
         
      })
        if(this.state.post01.length > 1){
            this.setState({
                errorMessage:'De kolom -2 bevat teveel items, deze kolom mag maximaal 1 item bevatten.',
                errorMessageActive:true,
            })          
    
        }
        else if(this.state.post02.length  > 1){
            this.setState({
                errorMessage:'De kolom -1 bevat teveel items, deze kolom mag maximaal 2 item bevatten.',
                errorMessageActive:true,
            })          
        } 
        else if(this.state.post00.length  > 3){
            this.setState({
                errorMessage:'De kolom 0 bevat teveel items, deze kolom mag maximaal 3 item bevatten.',
                errorMessageActive:true,
            })          
        }  
        else if(this.state.post11.length > 2){
            this.setState({
                errorMessage:'De kolom 1 bevat teveel items, deze kolom mag maximaal 2 item bevatten.',
                errorMessageActive:true,
            })          
        }
        else if(this.state.post12.length > 1){
            this.setState({ 
                errorMessage:'De kolom 2 bevat teveel items, deze kolom mag maximaal 1 item bevatten.',
                errorMessageActive:true,
            })          
        }*/
       
       if(this.state.post01.length ==  2 && this.state.post02.length  == 3 &&this.state.post00.length == 5 && this.state.post11.length  == 3 && this.state.post12.length  == 2 ){
            this.setState({
                afronden: true
            }) 

            console.log('check true')
        }    
    }

    onDragEnd = result => {
        this.setState({
            errorMessage:'',
           
        })
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const negative = reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );

            


            let state = { negative };


            if (source.droppableId === 'droppable2') {
                state = { 
                    natural: negative ,
                
                };
                this.checkDropLenght();

            }

            if (source.droppableId === 'droppable3') {
                state = { positive: negative,

                };
                this.checkDropLenght();

            }


            if (source.droppableId === 'droppable4') {
                state = { post01: negative,};
                this.checkDropLenght();

            }
            if (source.droppableId === 'droppable5') {
                state = { post02: negative,

                };
                this.checkDropLenght();

            }
            if (source.droppableId === 'droppable6') {
                state = { post00: negative,};
                this.checkDropLenght();

            }
            if (source.droppableId === 'droppable7') {
                state = { post11: negative,

                };
                this.checkDropLenght();

            }
            if (source.droppableId === 'droppable8') {
                state = { post12: negative,

                };
                this.checkDropLenght();

            }

            

            this.setState(state);

        } else {
            const result = move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );

//Begin of if-s
if(source.droppableId === 'droppable' && destination.droppableId === 'droppable2' ){
    this.setState({
        negative: result.droppable,
        natural: result.droppable2,
        errorMessage:''

    });
    this.checkDropLenght();
    this.checkLength();
    }
else  if(source.droppableId === 'droppable2' && destination.droppableId === 'droppable' ){
    this.setState({
        negative: result.droppable,
        natural: result.droppable2,
        errorMessage:''
  
    });   
    this.checkDropLenght(); 
    this.checkLength();
}
    
    
else  if(source.droppableId === 'droppable' && destination.droppableId === 'droppable3' ){
    this.setState({
        negative: result.droppable,
        positive: result.droppable3,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable3' && destination.droppableId === 'droppable' ){
    this.setState({
        negative: result.droppable,
        positive: result.droppable3,
        errorMessage:''
  
});
this.checkDropLenght();
this.checkLength();
}
else  if(source.droppableId === 'droppable2' && destination.droppableId === 'droppable3' ){
    this.setState({
        natural: result.droppable2,
        positive: result.droppable3,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable3' && destination.droppableId === 'droppable2' ){
    this.setState({
        natural: result.droppable2,
        positive: result.droppable3,
        errorMessage:''
  
});
this.checkDropLenght();
this.checkLength();
}



else if(source.droppableId === 'droppable' && destination.droppableId === 'droppable4' ){
    this.setState({
        negative: result.droppable,
        post01: result.droppable4,
        errorMessage:''

    });
    this.checkDropLenght();
    this.checkLength();
    }
else  if(source.droppableId === 'droppable4' && destination.droppableId === 'droppable' ){
    this.setState({
        negative: result.droppable,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable2' && destination.droppableId === 'droppable4' ){
    this.setState({
        natural: result.droppable2,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable4' && destination.droppableId === 'droppable2' ){
    this.setState({
        natural: result.droppable2,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable3' && destination.droppableId === 'droppable4' ){
    this.setState({
        positive: result.droppable3,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable4' && destination.droppableId === 'droppable3' ){
    this.setState({
        natural: result.droppable3,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable5' && destination.droppableId === 'droppable4' ){
    this.setState({
        post02: result.droppable5,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable4' && destination.droppableId === 'droppable5' ){
    this.setState({
        post02: result.droppable5,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable6' && destination.droppableId === 'droppable4' ){
    this.setState({
        post00: result.droppable6,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable4' && destination.droppableId === 'droppable6' ){
    this.setState({
        post00: result.droppable6,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable7' && destination.droppableId === 'droppable4' ){
    this.setState({
        post11: result.droppable7,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable4' && destination.droppableId === 'droppable7' ){
    this.setState({
        post11: result.droppable7,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable8' && destination.droppableId === 'droppable4' ){
    this.setState({
        post12: result.droppable8,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable4' && destination.droppableId === 'droppable8' ){
    this.setState({
        post12: result.droppable8,
        post01: result.droppable4,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable' && destination.droppableId === 'droppable5' ){
    this.setState({
        negative: result.droppable,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable5' && destination.droppableId === 'droppable' ){
    this.setState({
        negative: result.droppable,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable2' && destination.droppableId === 'droppable5' ){
    this.setState({
        natural: result.droppable2,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable5' && destination.droppableId === 'droppable2' ){
    this.setState({
        natural: result.droppable2,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable3' && destination.droppableId === 'droppable5' ){
    this.setState({
        positive: result.droppable3,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable5' && destination.droppableId === 'droppable3' ){
    this.setState({
        positive: result.droppable3,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable6' && destination.droppableId === 'droppable5' ){
    this.setState({
        post00: result.droppable6,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable5' && destination.droppableId === 'droppable6' ){
    this.setState({
        post00: result.droppable6,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable7' && destination.droppableId === 'droppable5' ){
    this.setState({
        post11: result.droppable7,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable5' && destination.droppableId === 'droppable7' ){
    this.setState({
        post11: result.droppable7,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable8' && destination.droppableId === 'droppable5' ){
    this.setState({
        post12: result.droppable8,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable5' && destination.droppableId === 'droppable8' ){
    this.setState({
        post12: result.droppable8,
        post02: result.droppable5,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable' && destination.droppableId === 'droppable6' ){
    this.setState({
        negative: result.droppable,
        post00: result.droppable6,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable6' && destination.droppableId === 'droppable' ){
    this.setState({
        post00: result.droppable6,
        negative: result.droppable,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable2' && destination.droppableId === 'droppable6' ){
    this.setState({
        natural: result.droppable2,
        post00: result.droppable6,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable6' && destination.droppableId === 'droppable2' ){
    this.setState({
        post00: result.droppable6,
        natural: result.droppable2,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable3' && destination.droppableId === 'droppable6' ){
    this.setState({
        positive: result.droppable3,
        post00: result.droppable6,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable6' && destination.droppableId === 'droppable3' ){
    this.setState({
        post00: result.droppable6,
        positive: result.droppable3,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable7' && destination.droppableId === 'droppable6' ){
    this.setState({
        post11: result.droppable7,
        post00: result.droppable6,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable6' && destination.droppableId === 'droppable7' ){
    this.setState({
        post11: result.droppable7,
        post00: result.droppable6,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable8' && destination.droppableId === 'droppable6' ){
    this.setState({
        post12: result.droppable8,
        post00: result.droppable6,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable6' && destination.droppableId === 'droppable8' ){
    this.setState({
        post12: result.droppable8,
        post00: result.droppable6,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable' && destination.droppableId === 'droppable7' ){
    this.setState({
        negative: result.droppable,
        post11: result.droppable7, 
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable7' && destination.droppableId === 'droppable' ){
    this.setState({
        negative: result.droppable,
        post11: result.droppable7,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable2' && destination.droppableId === 'droppable7' ){
    this.setState({
        natural: result.droppable2,
        post11: result.droppable7,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable7' && destination.droppableId === 'droppable2' ){
    this.setState({
        natural: result.droppable2,
        post11: result.droppable7,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable3' && destination.droppableId === 'droppable7' ){
    this.setState({
        positive: result.droppable3,
        post11: result.droppable7,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable7' && destination.droppableId === 'droppable3' ){
    this.setState({
        positive: result.droppable3,
        post11: result.droppable7, errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable8' && destination.droppableId === 'droppable7' ){
    this.setState({
        post12: result.droppable8,
        post11: result.droppable7,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable7' && destination.droppableId === 'droppable8' ){
    this.setState({
        post12: result.droppable8,
        post11: result.droppable7,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable' && destination.droppableId === 'droppable8' ){
    this.setState({
        negative: result.droppable,
        post12: result.droppable8,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable8' && destination.droppableId === 'droppable' ){
    this.setState({
        negative: result.droppable,
        post12: result.droppable8,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable2' && destination.droppableId === 'droppable8' ){
    this.setState({
        natural: result.droppable2,
        post12: result.droppable8,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable8' && destination.droppableId === 'droppable2' ){
    this.setState({
        natural: result.droppable2,
        post12: result.droppable8,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}

else  if(source.droppableId === 'droppable3' && destination.droppableId === 'droppable8' ){
    this.setState({
        positive: result.droppable3,
        post12: result.droppable8,
        errorMessage:''
  
    });
    this.checkDropLenght();
    this.checkLength();
}
else  if(source.droppableId === 'droppable8' && destination.droppableId === 'droppable3' ){
    this.setState({
        positive: result.droppable3,
        post12: result.droppable8,
        errorMessage:''
    });
    this.checkDropLenght();
    this.checkLength();
}


            console.log("eerste na slepen" +result);


        
        //Rechter rij


        }
    };

    componentWillMount(){
        console.log(this.props.qsortdata);
        
       }

       componentDidMount(){
           //this.addMarginTop();
       }

    openInstruction(){
        this.setState({
            instruction:true
        })
    }

    closeInstruction(){
        this.setState({
            instruction:false
        })
    }

    addMarginTop(){

       if( this.state.post00.length >2 ){
        document.getElementById('selection-blocks').style.marginTop = "10%";
       }else{
        document.getElementById('selection-blocks').style.marginTop = "0%";
       }
     
    }



    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
     //   const isDropDisabled = this.state.selected.length > 3;
       // console.log('is die false of true:' + isDropDisabled);
        return (

            <Col lg="12" md="12" sm="12" className="mb-4">
                {this.state.instruction ?
                <Row className="overlay-row">
                <Col  sm={3}></Col>
                <Col  sm={6}  className="overlay-col">
                    <h2>Instructie</h2>
                    <p>
                    Door met de muis erop te klikken, kunt u de gerangschikte stellingen verslepen naar het onderstaande schema.
Onder de -2, -1, 0, 1 en 2 rangschikt u zoveel stellingen als er blokjes beschikbaar zijn. Zoals u ziet zijn het exact 15 blokjes, dus alle stellingen moeten een plekje krijgen in het blokkenschema.
U kunt zoveel heen en weer schuiven met de stellingen als u wilt.
Wanneer u gereed bent, dan gaat u naar het volgende scherm en kunnen de stellingen niet meer worden verschoven.
                    </p>
                    <Button variant="primary"  className="button-sortdone" onClick={()=> this.closeInstruction()}>Sluiten</Button>
                </Col>
                </Row>:null}
                <Row>
                <Col sm="4">   <img src={localStorage.getItem('companyLogo')}  className="company-logo-left"/></Col>
                <Col sm="4" className="qsortdone">
                {this.state.afronden ? null: <Button variant="primary" className="button-restart color-orange" onClick={()=> this.openInstruction()}>Instructies <i class="far fa-question-circle"></i></Button>}
                {this.state.afronden ? null: <Button variant="primary" className="button-restart" onClick={()=> this.resetCards()}>Kaartjes herstellen</Button>}
                {this.state.afronden ? null:<Button variant="primary" className="button-sortdone" onClick={()=> this.checkDropLenght()}>Controleren</Button>}
          {this.state.afronden ?  <Link className="link-setup-afronden" onClick={()=> this.doneSorting()}to={{pathname: `/q-sort-check`,state: {questions: this.state.questions, key:this.state.key}}} >Volgende</Link> :   <span></span> }
            </Col>
            </Row>
            <Row>
                <Col sm="4"></Col>
                <Col sm="4">
                {this.state.errorMessageActive ? 
              <div className="error-message-qsort">
                <p>{this.state.errorMessage}</p>
              </div>
              :
                <div></div>
              }
                </Col>
            </Row>
           
                                            <Row>

<Col lg="12" className="block-middle-stelling-qsort">
    <h5>{this.state.stelling}</h5>
</Col>

</Row>
            <DragDropContext onDragEnd={this.onDragEnd}>
               
                
                <Row className="Drops">
                <Col lg="2" md="2" sm="2" className={Number(localStorage.getItem('amount')) ==  2 ? "mb-1 custom-Class position-negative one"  :"mb-1 custom-Class position-negative two"}>
                <div class="position-title"><h3>-2 | 2/{ 2-this.state.post01.length }<br/> (Minst mee eens) </h3></div>
                <Droppable droppableId="droppable4"  >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}>
                            {this.state.post01.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}>
                                            {item.vraag}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                {Number(localStorage.getItem('amount')) ==  2 ? 
                this.state.post01.length >= 2 ?   <span className="error-inside-qsort">De kolom -2 bevat teveel items, deze kolom mag maximaal 1 item bevatten.</span>: ''
                :
                this.state.post01.length >= 3 ?   <span className="error-inside-qsort">De kolom -2 bevat teveel items, deze kolom mag maximaal 2 items bevatten.</span>: ''
                }
                </Col>

                <Col  lg="2" md="2" sm="2"  className={Number(localStorage.getItem('amount')) ==  2 ?  "mb-1 custom-Class position-negative min-twee two":"mb-1 custom-Class position-negative min-twee three"}>
                <div class="position-title"><h3>-1 | 3/{ 3-this.state.post02.length } <br/><span className="whitetext">minst mee eens</span></h3></div>
                <Droppable droppableId="droppable5"   isDropDisabled={this.state.post02Open}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}>
                            {this.state.post02.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}>
                                            {item.vraag}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                {Number(localStorage.getItem('amount')) ==  2 ? 
                this.state.post02.length >= 3 ?   <span className="error-inside-qsort">De kolom -1 bevat teveel items, deze kolom mag maximaal 2 items bevatten.</span>: ''
                :
                this.state.post02.length >= 4 ?   <span className="error-inside-qsort">De kolom -1 bevat teveel items, deze kolom mag maximaal 3 items bevatten.</span>: ''
                }
                </Col>


                <Col  lg="2" md="2" sm="2"  className={Number(localStorage.getItem('amount')) ==  2 ? "mb-1 custom-Class position-natural  three" : "mb-1 custom-Class position-natural  four" }>
                <div class="position-title"><h3>0 | 5/{ 5-this.state.post00.length } <br/> (Neutraal)</h3></div>
                <Droppable droppableId="droppable6"  isDropDisabled={this.state.post00Open} >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}>
                            {this.state.post00.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}>
                                            {item.vraag}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                {Number(localStorage.getItem('amount')) ==  2 ? 
                this.state.post00.length >= 4 ?   <span className="error-inside-qsort">De kolom 0 bevat teveel items, deze kolom mag maximaal 3 items bevatten.</span>: ''
                :
                this.state.post00.length >= 6 ?   <span className="error-inside-qsort">De kolom 0 bevat teveel items, deze kolom mag maximaal 5 items bevatten.</span>: ''
                }
                </Col>

                <Col  lg="2" md="2" sm="2"  className={Number(localStorage.getItem('amount')) ==  2 ? "mb-1 custom-Class position-positive two" :"mb-1 custom-Class position-positive three"}>
                <div class="position-title"><h3>1 | 3/{ 3-this.state.post11.length }<br/><span className="whitetext">meest mee eens</span></h3></div>
                <Droppable droppableId="droppable7"   isDropDisabled={this.state.post11Open}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}>
                            {this.state.post11.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}>
                                            {item.vraag}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                {Number(localStorage.getItem('amount')) ==  2 ? 
                this.state.post11.length >= 3 ?   <span className="error-inside-qsort">De kolom 1 bevat teveel items, deze kolom mag maximaal 2 items bevatten.</span>: ''
                :
                this.state.post11.length >= 4 ?   <span className="error-inside-qsort">De kolom 1 bevat teveel items, deze kolom mag maximaal 3 items bevatten.</span>: ''
                }
                </Col>

                <Col  lg="2" md="2" sm="2"  className={Number(localStorage.getItem('amount')) ==  2 ? "mb-1 custom-Class position-positive one": "mb-1 custom-Class position-positive two"}>
                <div class="position-title"><h3>2 | 2/{ 2-this.state.post12.length }<br/> (Meest mee eens)</h3></div>
                <Droppable droppableId="droppable8"  isDropDisabled={this.state.post12Open}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}>
                            {this.state.post12.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}>
                                            {item.vraag}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                {Number(localStorage.getItem('amount')) ==  2 ? 
                this.state.post12.length >= 2 ?   <span className="error-inside-qsort">De kolom 2 bevat teveel items, deze kolom mag maximaal 1 item bevatten.</span>: ''
                :
                this.state.post12.length >= 3 ?   <span className="error-inside-qsort">De kolom 2 bevat teveel items, deze kolom mag maximaal 2 items bevatten.</span>: ''
                }
                
                </Col>
               
                              
                
                </Row>
                <Row className="row-colums" id="selection-blocks" >
             
             <Col className="mb-1 class-group-disagree">
                 <h4>Mee oneens</h4>
                 <Droppable droppableId="droppable">
             {(provided, snapshot) => (
                 <div
                     ref={provided.innerRef}
                     style={getListStyle(snapshot.isDraggingOver)}>
                     {this.state.negative.map((item, index) => (
                         <Draggable
                        
                             key={item.id}
                             draggableId={item.id}
                             index={index}>
                             {(provided, snapshot) => (
                                 <div
                                     ref={provided.innerRef}
                                     {...provided.draggableProps}
                                     {...provided.dragHandleProps}
                                     style={getItemStyle(
                                         snapshot.isDragging,
                                         provided.draggableProps.style
                                     )}>
                                     {item.vraag}
                                 </div>
                             )}
                         </Draggable>
                     ))}
                     {provided.placeholder}
                 </div>
             )}
         </Droppable>
             </Col>
             <Col className="mb-1 class-group-natural">
                 <h4>Neutraal</h4>
                 <Droppable droppableId="droppable2" >
             {(provided, snapshot) => (
                 <div
                     ref={provided.innerRef}
                     style={getListStyle(snapshot.isDraggingOver)}>
                     {this.state.natural.map((item, index) => (
                         <Draggable
                             key={item.id}
                             draggableId={item.id}
                             index={index}>
                             {(provided, snapshot) => (
                                 <div
                                     ref={provided.innerRef}
                                     {...provided.draggableProps}
                                     {...provided.dragHandleProps}
                                     style={getItemStyle(
                                         snapshot.isDragging,
                                         provided.draggableProps.style
                                     )}>
                                     {item.vraag}
                                 </div>
                             )}
                         </Draggable>
                     ))}
                     {provided.placeholder}
                 </div>
             )}
         </Droppable>
             </Col>
             <Col className="mb-1 class-group-agree">
                 <h4>Mee eens</h4>
                 <Droppable droppableId="droppable3" >
             {(provided, snapshot) => (
                 <div
                     ref={provided.innerRef}
                     style={getListStyle(snapshot.isDraggingOver)}>
                     {this.state.positive.map((item, index) => (
                         <Draggable
                             key={item.id}
                             draggableId={item.id}
                             index={index}>
                             {(provided, snapshot) => (
                                 <div
                                     ref={provided.innerRef}
                                     {...provided.draggableProps}
                                     {...provided.dragHandleProps}
                                     style={getItemStyle(
                                         snapshot.isDragging,
                                         provided.draggableProps.style
                                     )}>
                                     {item.vraag}
                                 </div>
                             )}
                         </Draggable>
                     ))}
                     {provided.placeholder}
                 </div>
             )}
         </Droppable>
             </Col>
         </Row>
            </DragDropContext>
            
</Col>

        );
    }
};

export default QSortingQsortScreen;
