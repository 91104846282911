import React from "react";
import { Container, Row, Col } from "shards-react";


import desktopImage from '../../assets/images/background-blobs.png';
import mobileImage from '../../assets/images/background-blobs.png';
import SortingQsortScreen from "../../components/qsort/SortingScreen";


const imageUrl = window.innerWidth >= 650 ? desktopImage : mobileImage;
const SortingQsortView = (props) => (
  <Container fluid className="main-content-container px-4 modal-background"  style={{backgroundImage: `url(${imageUrl})` }}>
    <SortingQsortScreen qsortdata={props.location.state}/>
    
  </Container>
);

export default SortingQsortView;
