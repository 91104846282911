import React from "react";

import FooterComponent from "../../components/Footer/FooterComponent";
import FrontendHomeComponent from "../../components/frontend";
import HeaderComponent from "../../components/Header/headerComponent";




const FrontendHome = () => (
    <div>
    <HeaderComponent/>
    <FrontendHomeComponent />
    <FooterComponent/>
    
    </div>
);

export default FrontendHome;
