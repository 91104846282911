import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";

import { Link } from "react-router-dom";
import AddUserAPM from "../../components/boards/AddUserApm";



const AddUserScreenApm = (props) => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title={"Gebruiker toevoegen"} subtitle="F-Fort" md="12" className="ml-sm-auto mr-sm-auto" />
      <Link to={{ pathname:'/mijn-borden' ,state: {key: props.location.state.projectKey,projectKey: props.location.state.projectKey, name: props.location.state.toolName, toolName: props.location.state.toolName, clientKey: props.location.state.clientKey, clientName: props.location.state.clientName, shortcode: props.location.state.shortcode}}}>Terug</Link>
    </Row>
    <AddUserAPM data={props.location.state}/>
    
  </Container>
);

export default AddUserScreenApm;
