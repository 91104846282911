import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import toast, { Toaster } from 'react-hot-toast';
import { ChromePicker } from 'react-color';


class AppDetailAddCategoryAPM extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            color:'',
            po:'',
            uploaded:false,
            categories:[],
    
        }

      }


      componentDidMount(){
          this.setState({
              categories:[]
          })
          localStorage.getItem('id')
        let categoriesRef = Firebase.database().ref('/clients/-beifkwefiwebfnw/boards/'+localStorage.getItem('id')+'/settings/categories');
        categoriesRef.on('value' , snapshot => {
          this.setState({categories:[]})
            snapshot.forEach((childSnap) => {
  
            let state = childSnap.val();
            
            this.setState(prevState => ({
              
              categories: [...prevState.categories, state],
            }));
          
            });
        })
      }

      addCategory(){
          
            var newPostKey = this.state.categories.length;
            Firebase.database().ref('/clients/-beifkwefiwebfnw/boards/'+localStorage.getItem('id')+'/settings/categories/'+newPostKey).set({
              bgcolor: this.state.color,
              key: this.state.categories.length,
              name: this.state.name,
              po: this.state.po,
              position: this.state.categories.length
              
          }).then((data)=>{
            toast.success('De categorie is toegevoegd!');
            this.setState({
                color:'',
                key:'',
                name:'',
                po:'',
                position:''
            })
          }).catch((error)=>{
              //error callback
              console.log('error ' , error)
          })
          

      }

      handleChangeComplete = (color) => {
        this.setState({ color: color.hex });
      };
      handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
      };
    
      handleClose = () => {
        this.setState({ displayColorPicker: false })
      };
          
    render() {
        const popover = {
            position: 'absolute',
            zIndex: '2',
          }
          const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          }
        return (
            <Row >
                         <Toaster />
                <Col lg="2"></Col>
                <Col lg="8" className="col-height">
                    <div className="edit-screen-detail">
                        
                        <h4>Categorie aanmaken</h4>
                        <br/>
                        <li><span className="align-left">Naam</span> <span className="align-right board-setup"><input type="text" value={this.state.name} onChange={(event)=> this.setState({name: event.target.value, uploaded: false})} /></span></li>
                        <li><span className="align-left">Kleur</span> <span className="align-right board-setup"> <button onClick={ this.handleClick }>Selecteer een kleur</button>
        { this.state.displayColorPicker ? <div style={ popover }>
          <div style={ cover } onClick={ this.handleClose }/>
          <ChromePicker color={ this.state.color }
        onChangeComplete={ this.handleChangeComplete } />
        </div> : null }</span></li>
                        <li><span className="align-left">Product owner</span> <span className="align-right board-setup"><input type="text" value={this.state.po} onChange={(event)=> this.setState({po: event.target.value, uploaded: false})} /></span></li>
                        
                        <span className="marginTop">
                            <Button className="save-button" variant="primary" onClick={()=> this.addCategory()}>Opslaan</Button> {this.state.uploaded ?<span className="post-it-update"> <i class="fas fa-check"></i>  Categorie is aangemaakt.</span> :null}<br />
                 
                        </span>

                       
                       
                    </div>

                    <div className="selected-categories">
                        <label>Geslecteerde categoriën {this.state.categories.length}/7</label>
                        <ul>
                            {this.state.categories.map((category)=>(
                                <li>{category.name}</li>
                            ))}
                        </ul>
                    </div>
                </Col>
                <Col lg="2"></Col>
            </Row>         
        );
    }
};

export default AppDetailAddCategoryAPM;
