import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";

import { Link } from "react-router-dom";
import PoEditCom from "../../components/appFrontend/poEdit";




const PoEditScreen = (props) => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Product bewerken" subtitle="F-Fort" md="12" className="ml-sm-auto mr-sm-auto" />
      <Link to={{pathname: `/mijn-bureau`,state: {}}}>terug</Link>

    </Row>
    <PoEditCom postit={props.location.state}/>
    
  </Container>
);

export default PoEditScreen;
