import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";

import { Link } from "react-router-dom";

import EdittedPost from "../../components/appFrontend/post-it";


const EditPostViewSecond = (props) => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Post-it bewerken" subtitle="F-Fort" md="12" className="ml-sm-auto mr-sm-auto" />
      <Link to={{pathname: `/dashboard`,state: {}}}>terug</Link>
  {/**     <img img src={require('../assets/images/Logo-Helmond.svg')}  style={{ width: '20%',
    float:' right',
    left: '75%',
    position: 'relative',
    marginTop: '-4%',
    marginBottom:'1%'}}/>*/}
    </Row>
    <EdittedPost postit={props.location.state}/>
    
  </Container>
);

export default EditPostViewSecond;
