import React, { Component } from 'react';

import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";

import Firebase from 'firebase';
import toast, { Toaster } from 'react-hot-toast';


class AddUserAPM extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            employee:'',
            from:'',
            till:'',
            target:'',
            targetDescription:'',
            uploaded:false,
            categories:[],
            dest: '',
            pass:'',
            alreayExist: false,
            existingUsers:[]

        }

      }

      makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        this.setState({pass: result});
     }


      componentDidMount(){
          this.setState({
              categories:[]
          })

          console.log(this.props.data.name)

          this.makeid(8);


        let users = Firebase.database().ref('/clients/-beifkwefiwebfnw/users/');
        users.once('value' , snapshot => {
            snapshot.forEach((childSnap) => {
  
            let state = childSnap.val();
       
            this.setState(prevState => ({
              
              existingUsers: [...prevState.existingUsers, state],
            }));
          
            });
           
        })
      
       
      }

      sendCredentials(){
          for( let i = 0; i < 50; i++){
            fetch('https://us-central1-f-fort-tool-2.cloudfunctions.net/emailSender?dest='+this.state.dest)
            .then(response => console.log('done'));
          }
 
      }

      

      writeUserData(authData){
        Firebase.database().ref('/clients/-beifkwefiwebfnw/users/'+authData.user.uid).set({
            name: this.state.name,
            email: this.state.dest,
            role:'user',
            uuid: authData.user.uid,
        }).then((data)=>{
            Firebase.database().ref('/clients/-beifkwefiwebfnw/users/'+authData.user.uid+'/accesto/'+this.props.data.id).set({

                clientId: this.props.data.id,
                name:this.props.data.name,
              
            }).then((data)=>{
                fetch('https://us-central1-f-fort-tool-2.cloudfunctions.net/emailSender?dest='+this.state.dest+'&pass='+this.state.pass+'&name='+this.state.name)
                .then(response => console.log('done'));
                Firebase.database().ref('/clients/-beifkwefiwebfnw/boards/'+this.props.data.id+'/participants/'+authData.user.uid).set({
                  email: this.state.dest,
                  name: this.state.name,
                  uuid: authData.user.uid,
              })
            }).then(() =>{
              this.makeid(8);
              this.setState({name:'', dest:''})
              toast.success('Gebruiker is uitgenodigd!');
            })
        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        })
      }

      addExistingUserToBoard(){
        console.log(this.state.existingUsers);
        for(var i = 0; i < this.state.existingUsers.length; i++){
          if(this.state.existingUsers[i].email === this.state.dest){
            Firebase.database().ref('/clients/-beifkwefiwebfnw/users/'+this.state.existingUsers[i].uuid+'/accesto/'+this.props.data.id).set({

              clientId: this.props.data.id,
              name: this.props.data.name
          })
          Firebase.database().ref('/clients/-beifkwefiwebfnw/boards/'+this.props.data.id+'/participants/'+this.state.existingUsers[i].uuid).set({
            email: this.state.dest,
            name: this.state.name,
            uuid: this.state.existingUsers[i].uuid,
        })
       .then(()=>{
          this.setState({
            name:'',
            dest:'',
            alreayExist: false
          })
          toast.success('Gebruiker is toegevoegd!');

          this.makeid(8);
        })
        }
      }
            
      }

      addUser(){
        Firebase.auth().createUserWithEmailAndPassword(this.state.dest, this.state.pass).then((authData) =>{
            this.writeUserData(authData)
        }).catch(error =>{
            switch (error.code) {
                case 'auth/email-already-in-use':
                  console.log(`Email address ${this.state.dest} already in use.`);
                  this.setState({
                    alreayExist:true,
                  })
                  break;
                case 'auth/invalid-email':
                  console.log(`Email address ${this.state.dest} is invalid.`);
                  break;
                case 'auth/operation-not-allowed':
                  console.log(`Error during sign up.`);
                  break;
                case 'auth/weak-password':
                  console.log('Password is not strong enough. Add additional characters including special characters and numbers.');
                  break;
                default:
                  console.log(error.message);
                  break;
              }
        })
      }

      cancelExistingUser(){
        this.setState({dest:'',name:'',alreayExist: false});
        this.makeid(8);
      }


          
    render() {
        return (
            <Row >
                    <Toaster />
                <Col lg="2"></Col>
                <Col lg="8" className="col-height">
                    <div className="edit-screen-detail">
                        
                        <h4>Bord aanmaken</h4>
                        <br/>
                        <li><span className="align-left">E-mail gebruiker</span> <span className="align-right board-setup"><input type="text" value={this.state.dest} onChange={(event)=> this.setState({dest: event.target.value, uploaded: false})} /></span></li>     
                        <li><span className="align-left">Naam gebruiker</span> <span className="align-right board-setup"><input type="text" value={this.state.name} onChange={(event)=> this.setState({name: event.target.value, uploaded: false})} /></span></li>   

                        <span className="marginTop">
                        {this.state.alreayExist ?
                                                <span>
                                                <span className="message">Deze gebruiker is al bekend, wil je hem/haar toevoegen aan dit bord?</span>
                                                <Button className="recover-button marginBottom" onClick={() => this.cancelExistingUser()}>Annuleren</Button><Button className="save-button marginBottom" onClick={() => this.addExistingUserToBoard()}>Voeg toe</Button>
                                                </span>
                                                : 
                        <span>  
                        <Button className="save-button" variant="primary" onClick={()=> this.addUser()}>Aanmaken</Button> {this.state.uploaded ?<span className="post-it-update"> <i class="fas fa-check"></i>  Bord is aangemaakt.</span> :null}<br />
                        </span>
                        

                        }
                        </span>

                       
                       
                    </div>
                </Col>
                <Col lg="2"></Col>
            </Row>         
        );
    }
};

export default AddUserAPM;
