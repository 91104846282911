import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';


class BoardsIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clientUUID:'-ohlnfwebk',
            orders:[],
            boards:[]
            
        };
    }

    getOrders(){


    }



    componentDidMount() {
        this.setState({
            boards:[]
        })

        let items = Firebase.database().ref('/clients/-beifkwefiwebfnw/boards/');
        items.on('value' , snapshot => {

            snapshot.forEach((childSnap) => {
                let newBoard = childSnap.val();  
            
                this.setState(prevState =>({
                    boards: [...prevState.boards, newBoard]
                }))
            })
        })

    }

    

    render() {
        return (
            <div>
            <Row className="col-sm-12">
              <Col sm={12} className="products-actions">
                    {/*<Link>Product bewerken</Link>
                    <Link>Meer acties</Link>*/}
                    <Link className="align-button-right" to={{pathname: `/mijn-borden/aanmaken`}} >Bord aanmaken</Link>
        </Col>
                <Col sm={12} className="products-table">
                
            <table>
                <tr>
                    <th>Bordnaam</th>
                    <th>Status</th>
                    <th>Aantal deelnemers</th>
                    <th>Actie</th>
                </tr>
                {this.state.boards.map((item)=>(
                    <tr >
                        <td>{item.name}</td>
                        <td>{item.boardStatus ? item.boardStatus : "n.v.t."}</td>
                        <td>{item.amountUsers ? item.amountUsers : 0}</td>
                        <td><Link to={{pathname:'/mijn-borden/details', state:{ item:item }}}>Bekijken</Link></td>
                        
                    </tr>
                ))}


                
                
            </table>
</Col>
   
            </Row>
            </div>



        );
    }
};

export default BoardsIndex;