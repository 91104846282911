import React from "react";
import { Container, Row, Col } from "shards-react";
import SettingsQsortScreen from "../../components/qsort/SettingsScreen";

import PageTitle from "../../components/common/PageTitle";

const SettingsQsortView = () => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Instellingen" subtitle="Q-sort" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <SettingsQsortScreen />
    
  </Container>
);

export default SettingsQsortView;
