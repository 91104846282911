import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";


class FrontendHomeComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


   

    

    render() {
       
        return (
        <Container fluid>
             
        
            
        </Container>

        );
    }
};

export default FrontendHomeComponent;